@import "src/styles/buttonEffect";
@import "src/styles/scrollbar";
@import "src/styles/textStyles";
@import "src/styles/iconSize";
@import "src/styles/screenSizes";
@import "../../MenuDropdown.module";

.wrapper {
  position: relative;
  cursor: pointer;
  transition: all .2s;
  color: var(--color-font-secondary);

  @extend %dropdownOption;
}

.options {
  position: absolute;
  width: 200px;
  max-height: 300px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background: var(--color-background-light);
  box-shadow: var(--box-shadow-light);
  overflow-y: auto;
  top: -15px;
  padding: 15px 0;

  gap: 10px;

  @extend %scrollbar;

  &.left {
    left: -200px;
    right: initial;
  }

  &.right {
    right: -200px;
    left: initial;
  }
}

@media screen and (max-width: $mobileScreen) {
  .options {
    width: 100px;

    &.left {
      left: -100px;
      right: initial;
    }

    &.right {
      right: -100px;
      left: initial;
    }
  }
}
