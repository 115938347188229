@import "src/styles/form";
@import "src/styles/textStyles";

.formWrapper {
  gap: 25px;
}

.perspective {
  max-width: 100%;
  height: auto;
  padding: 10px 15px;
  border: 1px solid var(--color-border-light);
  color: var(--color-border-dark);

  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    line-height: 18px;

    @extend %text-body-medium;
  }
}
