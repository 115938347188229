
%fade-in-animation {
  -webkit-animation: fadeIn .7s;
     -moz-animation: fadeIn .7s;
          animation: fadeIn .7s;
}


@-moz-keyframes fadeIn {
  from {
    transform: translateY(25px);
    opacity: 0;

    scale: .9;
  }

  to {
    transform: translateY(0px);
    opacity: 1;

    scale: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    transform: translateY(25px);
    opacity: 0;

    scale: .9;
  }

  to {
    transform: translateY(0px);
    opacity: 1;

    scale: 1;
  }
}

@keyframes fadeIn {
  from {
    transform: translateY(25px);
    opacity: 0;

    scale: .9;
  }

  to {
    transform: translateY(0px);
    opacity: 1;

    scale: 1;
  }
}
