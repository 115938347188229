@import "src/styles/formHorizontal";

.inputsWrapper {
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: $tabletScreen) {
  .inputsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: $mobileScreen) {
  .inputsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
