.wrapper {
  display: inherit;
}

.tooltip {
  content: attr(data-tooltip);
  animation: smooth-appear 0.25s ease-in;
  transform: translate(-50%, 0);
  color: var(--color-font-light);
}

@keyframes smooth-appear {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}
