@import "src/styles/screenSizes";

.formWrapper {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1 1;

  gap: 50px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;

  gap: 5px;
}

.inputsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 40px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: auto;
}

@media screen and (max-width: $tabletScreen) {
  .inputsWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: $mobileScreen) {
  .inputsWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
