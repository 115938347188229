@import "src/styles/scrollbar";
@import "src/styles/textStyles";
@import "src/styles/iconSize";
@import "src/styles/textEllipsis";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 15px;
}

.table {
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 0;
}

.tableHead {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.tableBody {
  display: block;
  flex: 1 1 auto;
  overflow-y: auto;

  @extend %scrollbar;
}

.tableBodyRow {
  display: table;
  table-layout: fixed;
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--color-border-light);
  }

  &:hover {
    background-color: var(--color-background-light);
  }

  @extend %text-body-medium;
}

.tableHeadRowCellContent {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  & > span {
    width: auto;
    min-width: 0;
    font-weight: 500;
    white-space: nowrap;

    @extend %text-ellipsis;
    @extend %text-body-medium;
  }
}

.tableBodyRowCellContent {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  & > span {
    width: auto;
    min-width: 0;
    white-space: nowrap;
    font-weight: inherit;

    @extend %text-ellipsis;
    @extend %text-body-medium;
  }
}

.tableHeadNumerationCell,
.tableBodyNumerationCell {
  width: 25px;
  padding: 0;
  text-align: left;
}
