@import "src/styles/iconSize";
@import "src/styles/textStyles";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 5px;
  border: 1px solid;
  border-radius: 10px;

  gap: 5px;

  &Extended {
    padding: 15px;

    gap: 10px;

    .iconWrapper {
      & > svg {
        @include icon-size(25px);
      }
    }

    .message {
      @extend %text-body-large;
    }

    .description {
      @extend %text-body-medium;
    }
  }

  &_success {
    background-color: var(--color-notification-success-background);
    border-color: var(--color-notification-success-border);
  }

  &_info {
    background-color: var(--color-notification-info-background);
    border-color: var(--color-notification-info-border);
  }

  &_warning {
    background-color: var(--color-notification-warning-background);
    border-color: var(--color-notification-warning-border);
  }

  &_caution {
    background-color: var(--color-notification-caution-background);
    border-color: var(--color-notification-caution-border);
  }

  &_error {
    background-color: var(--color-notification-error-background);
    border-color: var(--color-notification-error-border);
  }
}

.iconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;

  gap: 5px;

  & > svg {
    @include icon-size(20px);
  }

  &_success {
    color: var(--color-notification-success-icon);
  }

  &_info {
    color: var(--color-notification-info-icon);
  }

  &_warning {
    color: var(--color-notification-warning-icon);
  }

  &_caution {
    color: var(--color-notification-caution-icon);
  }

  &_error {
    color: var(--color-notification-error-icon);
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;

  gap: 0;
}

.message {
  line-height: 20px;

  @extend %text-body-medium;
}

.description {
  @extend %text-body-medium;
}

.closeButtonWrapper {
  position: absolute;
  top: 0;
  right: 5px;

  button {
    padding: 0;

    svg {
      color: var(--color-font-secondary);

      @include icon-size(15px);
    }
  }
}
