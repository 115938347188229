@import "src/styles/screenSizes";

.formWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 15px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;

  gap: 5px;
}

.groupWrapper {
  display: flex;
  flex-direction: row;

  gap: 15px;
}

.selectDropdownWrapper {
  max-width: 100%;
}

@media screen and (max-width: $mobileScreen) {
  .groupWrapper {
    flex-direction: column;
  }
}
