@import "src/styles/screenSizes";
@import "src/styles/textStyles";
@import "src/styles/iconSize";

.inputWrapper {
  font-size: 14px;
  line-height: 16px;
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: var(--color-background-light);
}

.input {
  font-size: inherit;
  font-weight: inherit;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  color: inherit;
  border: 1px solid;
  border-color: var(--color-border-light);
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: var(--color-font-secondary);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:read-only:not(:disabled) {
    background-color: var(--color-background-secondary);
    opacity: .5;
  }
}

.limitWrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  right: 0;
  color: var(--color-font-secondary);

  gap: 10px;

  @extend %text-body-small;

  &:empty {
    display: none;
  }
}

.limit {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  & > svg {
    @include icon-size(15px);
  }
}
