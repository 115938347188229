@import "src/styles/textStyles";
@import "src/styles/iconSize";
@import "/src/styles/filledButtonStyles";

$containersPaddings: 30px;
$wrapperWidth: 355px;

.dashboardInfoWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: $wrapperWidth;
  height: 100%;
  padding: $containersPaddings;
  background-color: var(--color-background-light);
}

.infoAboutDashboard {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  background-color: var(--color-background-light);
}

.trackersInfo {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.name {
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;

  @extend %text-heading-medium;
}

.trackersInfoText {
  margin-bottom: 10px;
}

.additionalDashboardInfo {
  color: var(--color-font-secondary);

  @extend %text-body-small;
}

.trackersInfoTitle {
  margin-bottom: 5px;

  @extend %text-heading-small;
}

.subTitle {
  display: flex;
  color: var(--color-font-secondary);

  gap: 8px;

  @extend %text-body-medium;
}

.usedTrackers {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  flex: 1 1;

  gap: 10px;
}

.usedTracker {
  max-width: 100%;

  @extend %text-body-medium;
  @include filled-button-styles(
      var(--color-green-secondary),
      var(--color-green-secondary),
      var(--color-blue-secondary-dark)
  );

  span {
    overflow: hidden;
    flex: 1 1;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  button {
    display: flex;
  }

  svg {
    @include icon-size(20px);
  }
}
