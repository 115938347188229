@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";
@import "src/styles/spinAnimation";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0;
  border-radius: 20px;
  width: 100%;
  background-color: var(--color-background-light);
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  gap: 8px;

  &:not(:first-child) {
    padding-top: 24px;
  }

  &:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--color-border-light);
  }
}

.expandButton {
  padding: 0;

  @extend %text-heading-medium;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;

  gap: 4px;
}

.heading {
  @extend %text-heading-medium;
}

.settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;

  gap: 8px;
}

.searches {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 52px;
  overflow: auto;

  gap: 8px;
}

.update {
  position: absolute;
  bottom: -21px;
  right: 0;

  & > button {
    height: 18px;
    width: 18px;
    font-weight: 400;
    padding: 0;

    @extend %text-body-medium;

    & > svg {
      @include icon-size(18px);
    }
  }
}

.placeholder {
  margin: auto;
  color: var(--color-font-disabled);

  @extend %text-body-medium;
}

.button {
  font-weight: 400;
  border-color: var(--color-border-light);

  @extend %text-body-medium;
}

.loader {
  @include spin-animation(1s);
}

.loaderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(256, 256, 256, 0.9);
  z-index: 1;
}

@media screen and (max-width: $mobileScreen) {
  .wrapper {
    padding: 0 0 16px 0;
  }

  .section {
    &:not(:first-child) {
      padding-top: 16px;
    }

    &:not(:last-child) {
      padding-bottom: 16px;
    }
  }

  .settings {
    flex: 0 0 100%;
    order: 2;
  }

  .searches {
    max-height: 75px;
  }
}
