@import "src/styles/iconSize";
@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .monthPickerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    gap: 10px;
  }

  .monthPickerPopper {
    z-index: 5;
  }
}

.monthPickerCalendar {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--color-background-light);
  box-shadow: var(--box-shadow-light);

  &[class~="react-datepicker"] {
    border-color: var(--color-border-light);
  }

  button[class~="react-datepicker__navigation--previous"],
  button[class~="react-datepicker__navigation--next"] {
    position: absolute;
    display: inline-block;
    padding: 1px;
    border: solid var(--color-border-dark);
    border-width: 0 2px 2px 0;

    @include icon-size(10px);
  }

  button[class~="react-datepicker__navigation--previous"] {
    top: 27px;
    left: 25%;
    transform: rotate(135deg);
  }

  button[class~="react-datepicker__navigation--next"] {
    top: 27px;
    right: 25%;
    transform: rotate(-45deg);
  }

  div[class~="react-datepicker__triangle"] {
    display: none;
  }

  div[class~="react-datepicker__month-container"] {
    display: flex;
    flex-direction: column;
    color: var(--color-font-dark);

    gap: 15px;

    div[class~="react-datepicker__header"] {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0;
      border: 0;
      background-color: transparent;
      color: var(--color-font-dark);

      @extend %text-heading-medium;
    }

    div[class~="react-datepicker__month"] {
      margin: 0;

      div[class~="react-datepicker__month-wrapper"] {
        display: flex;
        flex-direction: row;

        gap: 20px;

        div[class~="react-datepicker__month-text"] {
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 40px;
          border-radius: 100px;

          &:hover {
            background-color: var(--color-background-secondary-dark);
          }

          @extend %text-body-medium;
          @include icon-size(40px);

          &[class~="react-datepicker__month-text--today"] {
            outline: 1px solid var(--color-border-dark);
          }

          &[class~="react-datepicker__month-text--keyboard-selected"] {
            color: inherit;
            background-color: inherit;
          }

          &[class~="react-datepicker__month-text--selected"] {
            color: var(--color-font-light);
            border-radius: 50%;
            background-color: var(--color-background-dark);

            &:hover {
              background-color: var(--color-background-dark);
            }
          }

          &[class~="react-datepicker__month-text--disabled"] {
            color: var(--color-font-secondary);
            outline-color: var(--color-border);
            border-radius: 50%;
            background-color: transparent;
          }
        }
      }
    }
  }
}
