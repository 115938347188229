@import "src/styles/iconSize";

.wrapper {
  width: 100%;
  position: relative;
}

.icon {
  position: absolute;
  z-index: 1;
  left: 15px;
  top: 10px;

  fill: var(--color-font-dark);

  @include icon-size(20px);
}

.inputWithIcon {
  padding-left: 40px !important;
}

.inputWithClearButton {
  padding-right: 35px;
}

.clearButton {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  right: 10px;
  top: 10px;

  fill: var(--color-font-secondary);

  @include icon-size(20px);
}
