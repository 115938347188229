@import "src/styles/fadeInAnimation";

.dashboardBody {
  flex: 1 1;
  width: 100%;
}

.widgetTileWrapper {
  @extend %fade-in-animation;
}

.responsiveLayout {
  height: 100%;
}
