@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 3px;

  & > button {
    font-weight: 500;
    height: 20px;
    padding: 0;
  }
}

.button {
  overflow: hidden;
  justify-content: flex-start;
  width: 100%;
  border-radius: 0;
  padding: 10px;

  @extend %text-body-medium;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.alertContent {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 3px;

  & > span {
    flex: 0 0 auto;
  }

  & > button {
    font-weight: 500;
    height: 20px;
    padding: 0;
  }
}

.trackersButton {
  height: 20px;
  width: 100%;

  @extend %text-heading-extra-small;
}
