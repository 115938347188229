@import "src/styles/spinAnimation";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  height: 100%;

  gap: 16px;
}

.keywordsInfo {
  display: flex;
  flex-direction: column;
}

.keywordsTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  flex: 1 1;

  gap: 16px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 16px;
}

.button {
  width: 150px;
  font-weight: 400;
}

.loader {
  @include spin-animation(1s);
}
