@import "src/styles/textStyles";

.withError {
  width: 100%;
  position: relative;
}

.text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-width: 2px;
  border-style: solid;
  pointer-events: none;

  &::after {
    content: attr(data-text);
    position: absolute;
    bottom: -20px;
    color: inherit;

    @extend %text-body-small;
  }
}

.error {
  color: var(--color-red);
  border-color: var(--color-red);
}

.info {
  color: var(--color-blue);
  border-color: var(--color-blue);
}
