@import "src/styles/textStyles";
@import "src/styles/scrollbar";
@import "src/styles/iconSize";
@import "src/styles/spinAnimation";
@import "src/styles/textEllipsis";

$containersPaddings: 30px;
$wrapperWidth: 570px;

%separator {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  background-color: var(--color-background-secondary-dark);
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: $containersPaddings 0;
  background-color: var(--color-background-secondary-light);
  box-shadow: var(--box-shadow-light);
  width: $wrapperWidth;
  max-width: $wrapperWidth;
  max-height: 100%;

  gap: 20px;
}

.filterWrapper {
  display: flex;
  margin: 40px $containersPaddings 20px;
}

.eventsWrapper {
  display: flex;
  flex-direction: column;
  min-height: 0;

  gap: 10px;
}

.placeholder {
  padding: 10px $containersPaddings;
  text-align: center;
  color: var(--color-font-disabled);

  @extend %text-body-large;
}

.events {
  overflow: scroll;
}

.eventsTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 $containersPaddings;

  gap: 15px;

  @extend %text-heading-small;
}

.eventsTitleActions {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 15px;
}

.event {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px $containersPaddings;
  height: auto;
  width: 100%;

  gap: 15px;

  @extend %text-body-large;

  &:before {
    top: 0;

    @extend %separator;
  }

  &:hover {
    text-shadow: none;
  }

  &:last-child:after {
    bottom: 0;

    @extend %separator;
  }

  &.selected {
    background-color: var(--color-background-secondary-light);
  }

  span {
    white-space: nowrap;
    @extend %text-ellipsis;
  }

  svg {
    @include icon-size(20px);
  }
}

.eventButtons {
  display: flex;
  flex-direction: row;

  gap: 10px;
}

.eventButton {
  padding: 0;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  margin-top: auto;

  gap: 25px;

  & > button {
    min-width: 150px;
  }
}

.loader {
  @include spin-animation(1s);
}
