@import "/src/styles/screenSizes.scss";
@import "/src/styles/spinAnimation.scss";
@import "/src/styles/textStyles.scss";

.passwordForm {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 20px;
}

.subTitle {
  text-align: center;

  @extend %text-body-large;
}

.input {
  display: flex;
  width: 100%;
  color: var(--color-font-dark);
}

.resendButton,
.submitButton {
  margin: 20px auto 0;
  width: 250px;
}

.loader {
  @include spin-animation(1s);
}
