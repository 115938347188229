@import "src/styles/form";

.formWrapper {
  gap: 35px;
}

.textWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 3px;

  & > button {
    font-weight: 400;
    text-decoration: underline;
    padding: 0;
  }
}
