@import "../../generalStyles/generalPreviewStyles.module.scss";
@import "src/styles/iconSize";
@import "src/styles/textEllipsis";

.tableWrapper {
  max-width: 1000px;
}

.cloudWrapper {
  max-width: 1250px;
  max-height: 500px;
}

.tableCellKeyword {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  span {
    text-transform: capitalize;

    @extend %text-ellipsis;
  }
}

.tableCellKeywordTooltip {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 5px;
  }

  span {
    width: auto;
    text-transform: capitalize;
    white-space: nowrap;

    @extend %text-ellipsis;
  }

  svg {
    flex: 0 0 auto;
  }
}

.tableCellTracker {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  & > svg {
    flex: 0 0 auto;
    color: var(--color-font-secondary);

    @include icon-size(15px);
  }

  span {
    @extend %text-ellipsis;
  }
}
