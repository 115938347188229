@import "src/styles/scrollbar";
@import "src/styles/textStyles";

.tabs {
  display: flex;
  flex: 0 0 auto;
  overflow-x: auto;
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px solid var(--color-border-light);

  gap: 40px;

  &::-webkit-scrollbar {
    height: 2px;
  }

  @extend %scrollbar;
}

.tab {
  position: relative;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  color: var(--color-font-secondary);
  border: none;
  text-decoration: none !important;

  &Active {
    color: var(--color-font-dark);

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background-color: var(--color-grey);
    }
  }

  @extend %text-body-large;
}
