.checkbox {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  opacity: 1;

  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    cursor: initial;
    opacity: .25;
  }

  & > svg {
    width: 20px;
    height: 20px;
  }
}
