.w-md-editor {
  flex: 1 1 auto;
  height: 400px !important;
}

.w-md-editor-text {
  height: 100%;
}

.w-md-editor-toolbar {
  li {
    & > button {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px;

      & > svg {
        height: 15px;
        width: 15px;
      }
    }
  }
}

.w-md-editor-toolbar-divider {
  vertical-align: initial;
}
