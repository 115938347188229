@import "src/styles/scrollbar";

.tableCards {
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1 1 auto;
  flex-direction: column;
  height: 0;
  min-height: 300px;

  @extend %scrollbar;
}
