@import "src/styles/textStyles";
@import "src/styles/iconSize";
@import "src/styles/spinAnimation";
@import "src/styles/screenSizes";
@import "src/styles/textEllipsis";

.wrapper {
  display: flex;
  flex-direction: column;
  width: max-content;
  background-color: var(--color-background-light);
  color: var(--color-font-dark);
  padding: 24px 32px;
  box-shadow: var(--box-shadow);
  position: relative;
  min-width: 600px;
  border-radius: 20px;
  height: fit-content;
  margin: auto;

  gap: 24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
  height: 100%;
  flex: 1 1 auto;

  @extend %text-body-large;

  & > span {
    text-align: center;
  }
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  & > span {
    white-space: nowrap;

    @extend %text-ellipsis;
  }

  &Small {
    @extend %text-heading-small;
  }

  &Medium {
    @extend %text-heading-medium;
  }

  &Large {
    font-size: 24px;

    @extend %text-heading-large;
  }
}

.closeButton {
  color: var(--color-font-secondary);
  margin-left: auto;
  margin-bottom: auto;
  border-color: var(--color-font-secondary);
  padding: 4px;
  height: auto;

  & > svg {
    @include icon-size(15px);
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 24px;

  gap: 24px;

  & > button {
    white-space: nowrap;
    min-width: 150px;
  }
}

.loader {
  @include spin-animation(1s);
}

@media screen and (max-width: $mobileScreen) {
  .wrapper {
    min-width: 100%;
    padding: 16px 24px;

    gap: 16px;
  }

  .buttonsWrapper {
    gap: 16px;

    & > button {
      width: auto;
      min-width: auto;
    }
  }
}
