%scrollbar {
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, .025);
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 0;
    background-color: rgba(0, 0, 0, .1);
  }
}
