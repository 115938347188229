@import "../../generalStyles/generalPreviewStyles.module.scss";
@import "src/styles/iconSize";
@import "src/styles/screenSizes";
@import "src/styles/textEllipsis";

.chartWrapper {
  flex-direction: row;
}

.trackerNameIcon {
  flex: 0 0 auto;
  border-radius: 50%;

  @include icon-size(15px);
}

.tableChart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  max-height: 100%;
  height: auto;
  min-height: 0;
  overflow: auto;
  padding: 0 10px;
}

.tableCell {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  & > .tableCellValue {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > svg {
    flex: 0 0 auto;
  }

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.trackerNameTableCell {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  & > .tableCellValue {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
  }

  & > svg {
    flex: 0 0 auto;
  }

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.trackerNameTableCell  > svg {
  color: var(--color-font-secondary);

  @include icon-size(15px);
}

.pieChartValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 5px;

  & > span {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > div {
    flex: 1 1;
    min-width: 0;
  }
}

.pieChartLabelWrapper,
.pieChartTooltipLabelWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  gap: 5px;

  & > svg {
    flex: 0 0 auto;

    @include icon-size(15px);
  }
}

.pieChartLabelWrapper > svg {
  color: var(--color-font-secondary);
}

.pieChartLabel {
  white-space: nowrap;
  min-width: 0;

  @extend %text-ellipsis;
}

@media screen and (max-width: $mobileScreen) {
  .tableChart {
    display: none;
  }
}
