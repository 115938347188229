@import "/src/styles/screenSizes.scss";
@import "/src/styles/textStyles.scss";

.login {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 568px;
  margin: auto;
  padding: 24px;

  gap: 25px;
}

.title {
  margin: auto;
  text-align: center;

  @extend %text-heading-extra-large;
}
