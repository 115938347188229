@import 'src/styles/textStyles';
@import 'src/styles/iconSize';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 4px;
  z-index: 10;
  max-width: 400px;
  color: var(--color-font-light);
  background: rgba(36, 43, 67, .9);
  margin: 0 30px;

  gap: 10px;
}

.title {
  line-height: 14px;

  @extend %text-body-medium;
}

.items {
  display: flex;
  flex-direction: column;

  gap: 5px;

  @extend %text-body-small;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 10px;
}

.itemTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  gap: 5px;
}

.itemLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  gap: 5px;

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1 1;
  }

  & > svg {
    flex: 0 0 auto;
  }
}
