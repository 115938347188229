@import "src/styles/iconSize";
@import "src/styles/textStyles";

$padding: 30px;
$width: 570px;

%separator {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  background-color: var(--color-background-secondary-dark);
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: $padding;
  background-color: var(--color-background-secondary-light);
  box-shadow: var(--box-shadow-light);
  width: $width;
  max-width: $width;
  height: 100%;

  gap: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 40px 0 0;

  gap: 10px;
}

.formGroup {
  display: flex;
  flex-direction: row;

  gap: 10px;

  & > div {
    flex: 1 1;
  }
}

.content {
  display: flex;
  overflow: auto;
  flex: 1 1;
  flex-direction: column;
  margin-right: -$padding;
  margin-left: -$padding;

  gap: 50px;
}

.section {
  display: flex;
  flex-direction: column;
  height: 100%;

  gap: 10px;
}

.sectionTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 8px $padding;

  gap: 10px;

  & > span {
    @extend %text-heading-medium;
  }
}

.sectionActions {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
}

.sectionItems {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.sectionItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px $padding;
  text-align: left;
  border: none;

  gap: 15px;

  @extend %text-body-large;

  &:before {
    top: 0;

    @extend %separator;
  }

  &:last-child:after {
    bottom: 0;

    @extend %separator;
  }

  &:hover {
    .sectionItemActionsHover {
      display: flex;
    }
  }
}

.sectionItemActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 10px;
}

.sectionItemActionsHover {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 8px;

  svg {
    @include icon-size(17px);
  }
}

.authorWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-font-disabled);

  gap: 5px;

  @extend %text-body-medium;
}

.itemButton {
  padding: 0;
  font-weight: 400;
  height: 25px;
  flex: 1 1;
  justify-content: flex-start;
  min-width: 0;

  gap: 10px;
}

.itemDeleteButton {
  padding: 0;
  font-weight: 400;
  height: 25px;
  flex: 0 0 auto;
  text-align: left;
}

.sectionItemIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  & > svg {
    @include icon-size(20px);
  }
}

.sectionItemName {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  gap: 5px;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sectionItemButtons {
  display: flex;
  flex-direction: row;

  gap: 10px;

  button {
    padding: 0;
  }
}

.placeholder {
  padding: 10px $padding;
  text-align: center;
  color: var(--color-font-disabled);

  @extend %text-body-large;
}
