@import "src/styles/textStyles";

:root {
  --rs-text-link: var(--color-font-dark) !important;
  --rs-text-link-hover: inherit !important;
  --rs-text-link-active: inherit !important;
  --rs-text-primary: var(--color-font-dark) !important;
  --rs-text-disabled: var(--color-font-disabled) !important;
  --rs-text-secondary: var(--color-font-secondary) !important;

  --rs-state-focus-shadow: var(--box-shadow-light) !important;
  --rs-state-hover-bg: var(--color-background-secondary-light) !important;

  --rs-border-primary: var(--color-border-light) !important;

  --rs-shadow-overlay: var(--box-shadow-light) !important;

  --rs-input-focus-border: var(--color-border-light) !important;
  --rs-input-disabled-bg: var(--color-background-light) !important;

  --rs-listbox-option-selected-bg: var(--color-background-secondary-light) !important;
  --rs-listbox-option-selected-text: var(--color-font-dark) !important;
  --rs-listbox-option-hover-bg: var(--color-background-secondary-light) !important;
  --rs-listbox-option-hover-text: var(--color-font-dark) !important;

  --rs-picker-value: var(--color-font-dark) !important;
  --rs-color-focus-ring: transparent !important;
  --rs-close-button-hover-color: var(--color-font-secondary) !important;
}

.rs-picker {
  width: 100%;
}

.rs-picker-toggle {
  height: 40px;
  border-radius: initial;
  align-content: center;
  padding: 8px 16px !important;

  &:hover:not(.rs-btn-disabled) {
    box-shadow: var(--box-shadow-light);
  }
}

.rs-picker-clean {
  top: 9px !important;
}

.rs-picker-caret-icon {
  top: 10px !important;
}

.rs-picker-popup {
  z-index: 1000;
  border-radius: 20px;
  max-height: 240px;
  box-shadow: var(--box-shadow-light);
}

.rs-tree-view {
  max-height: 180px;
}

.rs-picker-list-item {
  font-size: 14px;

  @extend %text-body-medium;
}

.rs-picker-toggle-indicator {
  display: flex;

  & > svg {
    width: 20px;
    height: 20px;
  }
}

.rs-table-header-row-wrapper {
  color: var(--color-font-dark);
}

.rs-tree-node-active,
.rs-tree-node-active:focus {
  .rs-tree-node-label:hover {
    background-color: var(--color-background-secondary-light);
  }
}

.rs-picker-popup.rs-picker-select-menu {
  margin-top: 8px;
  margin-bottom: 8px;
}

.rs-picker-popup {
  &[class*="placement-top"] {
    margin-top: -5px;
  }

  &[class*="placement-bottom"] {
    margin-top: 5px;
  }

  &[class*="placement-right"] {
    margin-left: 5px;
  }

  &[class*="placement-left"] {
    margin-left: -5px;
  }
}

.rs-picker-select-menu-item-active,
.rs-picker-select-menu-item-active:hover {
  background-color: var(--color-background-secondary-light);
}
