@import "src/styles/iconSize";

.linkButton {
  padding: 0;

  @include icon-size(25px);

  & > a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include icon-size(25px);
  }

  svg {
    @include icon-size(15px);
  }
}
