@import "src/styles/iconSize";

.tiles {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 70px 30px 30px;

  gap: 15px;
}

.tilesSection {
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 15px;
}

.tilesSectionTitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  overflow: hidden;
  align-items: center;
  align-self: flex-start;
  flex: 1;
  width: 100%;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-font-secondary);

  gap: 10px;

  &:after {
    flex: 1;
    height: 1px;
    content: "";
    background-color: var(--color-border-light);
  }
}

.tip {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-right: 0;
  text-align: center;
  color: var(--color-font-secondary);
  padding: 30px;
  width: 260px;

  gap: 15px;

  & > svg {
    width: 120px;
    height: 75px;
  }
}

.widgetIcon {
  @include icon-size(90%);
}
