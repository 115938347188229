@import "src/styles/textStyles";
@import "src/styles/textEllipsis";
@import "src/styles/spinAnimation";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  gap: 15px;
}

.keywordsSearchInput {
  flex: 1 1;
  min-width: 250px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  gap: 15px;
}

.keywordsFilterSwitch {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;

  @extend %text-body-medium;
}

.showDuplicatedCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  gap: 5px;

  @extend %text-body-medium;
}

.smartSelectButton{
  display: flex;
  margin: auto;
  min-width: 100px;
  max-width: 150px;

  @extend %text-ellipsis;
}

.loader {
  @include spin-animation(1s);
}
