@import "src/styles/textStyles";
@import "src/styles/screenSizes";

.wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  width: 100%;
  padding: 24px;
  position: relative;

  gap: 15px;

  .content {
    height: 100%;

    & > div {
      height: 100%;
    }
  }
}

.addWidgetButtonWrapper {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 25px;
  z-index: 5;
  width: fit-content;
}

.preloader {
  margin: auto;
}

@media screen and (max-width: $mobileScreen) {
  .wrapper {
    padding: 16px;
  }
}
