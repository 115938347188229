@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/textEllipsis";
@import "/src/styles/screenSizes";

.article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  border: 1px solid var(--color-border-light);
  border-radius: 4px;

  gap: 10px;
}

.articleHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  gap: 10px;
}

.articleLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  flex: 1 0 auto;

  gap: 5px;

  & > svg {
    @include icon-size(15px);
  }

  @extend %text-body-medium;
}

.articleContent {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 5px;
}

.articleTitle {
  white-space: nowrap;

  @extend %text-ellipsis;
  @extend %text-heading-medium;
}

.articleDescription {
  &Truncated {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    @extend %text-body-medium;
  }

  &Full {
    display: block;
    margin: auto;

    @extend %text-body-medium;
  }
}

.articlesPagination {
  display: flex;
  flex-direction: row;
}

.articleFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 20px;
}

.toggleTruncateButton {
  padding: 0;
  border: none;
  height: fit-content;

  @extend %text-body-medium;
}

.articleSource {
  width: auto;
  color: var(--color-font-secondary);

  @extend %text-body-small;
  @extend %text-ellipsis;
}

.articleDate {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  color: var(--color-font-secondary);

  gap: 5px;

  & > svg {
    @include icon-size(20px);
  }

  @extend %text-body-small;
}

@media screen and (max-width: $mobileScreen) {
  .articleLink {
    display: none;
  }
}
