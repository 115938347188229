.wrapper {
  display: flex;
  flex-direction: column;
  width: 1000px;
  height: 0;
  flex: 1 1 auto;
  background-color: var(--color-background-dark);

  gap: 20px;

  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 60px 0 25px;
  }

  iframe {
    height: 0 !important;
    flex: 1 1 auto;
  }
}
