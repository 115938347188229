@import "/src/styles/textStyles";
@import "/src/styles/iconSize";
@import "/src/styles/filledButtonStyles";

$buttonShadow: 2px 2px 6px rgba(31, 31, 31, .25);

.button {
  border: 1px solid;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 5px;

  & > span {
    white-space: nowrap;
  }

  svg {
    flex: 0 0 auto;

    @include icon-size(20px);
  }

  @extend %text-label-thick-large;
}

// BUTTON TYPE STYLES
.button-primary {
  @include filled-button-styles(
    var(--color-brand),
    var(--color-brand-dark),
    var(--color-font-light)
  );
}

.button-dark {
  @include filled-button-styles(
    var(--color-blue-secondary-dark),
    var(--color-blue-secondary-dark),
    var(--color-font-light)
  );
}

.button-secondary {
  @include filled-button-styles(
    var(--color-white-secondary),
    var(--color-white-secondary),
    var(--color-font-dark)
  );
}

.button-success {
  @include filled-button-styles(
    var(--color-green),
    var(--color-green-dark),
    var(--color-font-light)
  );
}

.button-error {
  @include filled-button-styles(
    var(--color-red),
    var(--color-red-dark),
    var(--color-font-light)
  );
}

// BUTTON STYLE STYLES
.button-filled {
  &:disabled {
    color: var(--color-font-disabled);
    border-color: var(--color-button-background-disabled);
    background-color: var(--color-button-background-disabled);
    box-shadow: none;

    &:hover {
      border-color: var(--color-button-background-disabled);
      background-color: var(--color-button-background-disabled);
      box-shadow: none;
    }
  }
}

.button-outlined {
  color: var(--color-font-dark);
  border-color: var(--color-border-dark);

  &:hover,
  &:focus {
    background-color: transparent;
  }

  &:hover {
    box-shadow: $buttonShadow;
  }

  &:disabled {
    color: var(--color-font-secondary);
    border-color: var(--color-button-background-disabled);
    background-color: transparent;
    box-shadow: none;
  }
}

.button-transparent {
  border-color: transparent;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  &:hover {
    box-shadow: transparent;
    text-shadow: transparent;

    &:not(:disabled) {
      text-decoration: underline;

      svg {
        filter: drop-shadow(1px 1px 2px var(--color-box-shadow-hover));
      }
    }
  }

  &:disabled {
    color: var(--color-font-secondary);
    box-shadow: none;
    text-shadow: none;
  }
}

.button-clear {
  border-color: transparent;
}

// BUTTON SIZES STYLES
.button-small {
  height: 30px;
  padding: 5px 15px;
}

.button-medium {
  height: 40px;
  padding: 10px 20px;
}

.button-large {
  height: 50px;
  padding: 15px 25px;
}
