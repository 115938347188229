@import "src/styles/iconSize";
@import "src/styles/textStyles";

.placeholder {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  color: var(--color-font-secondary);

  gap: 15px;

  @extend %text-body-medium;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;
}

.title {
  color: var(--color-font-dark);

  @extend %text-heading-medium;
}

.subTitle {
  @extend %text-body-medium;
}

.button {
  color: var(--color-font-dark);
  font-weight: 400;
}

.placeholder__withIcon {
  width: 100%;
  padding: 20px;
  color: var(--color-font-disabled);

  gap: 20px;

  @extend .placeholder;

  & > svg {
    max-height: 200px;

    @include icon-size(80%);
  }

  & > span {
    color: var(--color-font-disabled);

    @extend %text-body-medium;
  }
}
