@import "src/styles/textStyles";
@import "src/styles/iconSize";

.wrapper {
  max-width: 100%;
  height: auto;
  border-color: transparent;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--color-background-secondary);

  &Active {
    border-color: var(--color-border-dark);

    &:focus, &:hover {
      border-color: var(--color-border-dark);
    }
  }

  &Empty {
    padding: 8px;
  }

  svg {
    @include icon-size(18px);
  }
}

.perspective {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 18px;

  @extend %text-body-medium;
}
