@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/textEllipsis";

.wrapper {
  display: flex;
  flex-direction: row;
  min-width: 0;

  gap: 5px;
}

.actions:empty {
  display: none;
}

.button {
  padding: 0;
  font-weight: 400;
  height: auto;

  @extend %text-body-small;
}

.labels {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 22px;
  justify-content: flex-start;
  min-width: 0;

  gap: 5px 10px;

  &Expanded {
    overflow: auto;
    max-height: 76px;
  }
}

.label {
  display: flex;
  align-items: center;
  min-width: 0;
  height: 22px;

  gap: 5px;

  &Trend {
    flex: 0 0 auto;
  }

  & > svg {
    flex: 0 0 auto;
    color: var(--color-font-secondary);

    @include icon-size(15px);
  }
}

.labelName {
  white-space: nowrap;

  @extend %text-body-small;
  @extend %text-ellipsis;
}
