@import "src/styles/iconSize";
@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 0;

  gap: 15px;
}

.nameWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  overflow: hidden;

  gap: 5px;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    color: var(--color-font-secondary);

    @include icon-size(20px);
  }
}

.metadataWrapper {
  max-width: 50%;
  color: var(--color-font-secondary);
}

.authorWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  & > svg {
    flex: 0 0 auto;
  }

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-font-disabled);

    @extend %text-body-medium;
  }
}
