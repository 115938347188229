@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  gap: 5px;

  & > span {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @extend %text-heading-extra-small;

    span, div {
      gap: 0;
    }
  }
}
