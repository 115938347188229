%textStyles {
  font-size: 14px;
  line-height: 20px;
}

.passwordInput {
  display: flex;
  flex-direction: column;
}

.inputWrapper {
  position: relative;
}

.input > input {
  padding-right: 40px;
}

.showButton {
  position: absolute;
  top: 50%;
  right: 10px;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
}

.passwordRequirements {
  position: absolute;
  z-index: 30;
  bottom: -5px;
  left: 50%;
  width: 100%;
  padding: 10px 15px;
  transform: translate(-50%, 100%);
  border-radius: 4px;
  background-color: var(--color-background-light);
  box-shadow: var(--box-shadow-light);

  & > span {
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px;

    @extend %textStyles;
  }
}

.passwordRequirementsList {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  gap: 5px;

  & > li {
    list-style-type: none;

    @extend %textStyles;

    &:before {
      display: inline-block;
      min-width: 11px;
      margin-right: 5px;
      content: "•";
      text-align: center;
    }

    &.done {
      color: var(--color-green);

      &:before {
        content: "✓";
      }
    }
  }
}
