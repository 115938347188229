@import "src/styles/textStyles";
@import "src/styles/iconSize";
@import "src/styles/filledButtonStyles";

$containersPaddings: 30px;
$wrapperWidth: 355px;

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: $wrapperWidth;
  height: 100%;
  padding: $containersPaddings;
  background-color: var(--color-background-light);

  gap: 50px;
}

.dashboard {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-light);

  gap: 10px;
}

.dashboardName {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;

  @extend %text-heading-medium;
}

.dashboardInfo {
  color: var(--color-font-secondary);

  @extend %text-body-small;
}

.eventsWrapper {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.eventsTitle {
  @extend %text-heading-small;
}

.events {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.event {
  max-width: 100%;

  span {
    overflow: hidden;
    flex: 1 1;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  button {
    display: flex;
  }

  svg {
    @include icon-size(20px);
  }

  @extend %text-body-medium;
  @include filled-button-styles(
      var(--color-green-secondary),
      var(--color-green-secondary),
      var(--color-blue-secondary-dark)
  );
}
