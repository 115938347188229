@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/textEllipsis";
@import "src/styles/scrollbar";

.tableBody {
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1 1 auto;
  height: 0;
  min-height: 150px;

  @extend %scrollbar;

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-bottom: 1px solid var(--color-border-light);

    &:last-child {
      width: 100%;
      border-bottom: none;
    }
  }

  td {
    overflow: hidden;
    padding: 20px 15px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    @extend %text-body-medium;

    &:last-of-type {
      position: relative;
      display: flex;
      align-items: center;
      overflow: visible;
      min-width: 100%;
    }

    svg {
      @include icon-size(20px);
    }

    span {
      width: auto;

      @extend %text-ellipsis;

      &:has(span) {
        display: flex;
        align-items: center;
        flex-direction: row;

        gap: 7.5px;
      }
    }
  }
}

.shortTableBody {
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 165px;

  &.shortTableBodyEmpty {
    tr {
      position: static;
    }

    td {
      padding: 10px 0;
      vertical-align: bottom;
    }
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
