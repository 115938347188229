@import "src/styles/textStyles";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: var(--color-background-light);
  border-radius: 30px;

  gap: 10px;

  & > div {
    width: 100%;
  }
}

.select {
  display: flex;
  align-items: center;
  border-radius: 25px;
  width: 100%;
  border: 1px solid var(--color-border-light);

  gap: 5px;

  &:has(input:not(:disabled)):hover {
    box-shadow: var(--box-shadow-light);
  }

  @extend %text-body-medium;

  &Error {
    border-color: var(--color-red);
  }
}

.input {
  div {
    background-color: transparent;
  }

  input {
    padding: 0 30px 0 15px;
    border: 0;
  }
}

.dropdown {
  min-width: 100%;
}
