@import "src/styles/textStyles";
@import "src/styles/textEllipsis";
@import "src/styles/fadeInAnimation";

.tagWord {
  display: flex;
  overflow: hidden;
  align-items: center;
  color: var(--color-font-light);
  text-transform: capitalize;

  gap: 5px;

  span {
    flex: 1 1;
    white-space: nowrap;

    @extend %text-ellipsis;
    @extend %text-body-large;
  }
}

.tagTip {
  color: var(--color-font-light);

  @extend %text-body-small;
}

.relatedWords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 0 5px;
}

.relatedWord {
  color: var(--color-font-light);
  text-transform: capitalize;

  @extend %text-body-medium;
}
