@import "src/styles/iconSize";

.viewButton {
  position: relative;
  padding: 0;
  border-radius: 4px;
  height: auto;

  svg {
    @include icon-size(24px);
  }
}
