@import "/src/styles/screenSizes";

.table {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0 0 8px 8px;
  background-color: var(--color-background-light);

  & > *:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  & > *:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
