@import "/src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 568px;
  margin: auto;
  padding: 24px;

  gap: 25px;
}

.title {
  margin: auto;
  text-align: center;

  @extend %text-heading-extra-large;
}

.termsAndConditions {
  text-align: center;

  a {
    text-decoration: underline;
  }

  @extend %text-body-extra-small;
}

.info {
  display: flex;
  flex-direction: column;

  gap: 20px;
}
