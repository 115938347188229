%truncated-placeholder {
  &:placeholder-shown {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &::placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &::-webkit-input-placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &::-moz-placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:-ms-input-placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
