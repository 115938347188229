@import "src/styles/textStyles";
@import "../../styles";

.title {
  justify-content: flex-start;
  align-items: flex-start;
}

.heading {
  line-height: 40px;
}

.subHeading {
  color: var(--color-font-secondary);
  font-size: 16px;

  @extend %text-body-medium;
}

.subTitle {
  display: flex;
  flex-direction: column;
}
