.wrapper {
  display: flex;
  flex-direction: column;

  gap: 15px;

  & > ul {
    margin: 0;
  }
}
