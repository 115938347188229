@import "src/styles/textStyles";
@import "src/styles/textEllipsis";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 16px 24px;
  border: 1px solid var(--color-border-dark);
  background-color: var(--color-background-light);
  cursor: pointer;
  min-width: 0;

  gap: 12px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.heading {
  padding: 4px 12px;
  border-radius: 30px;
  color: var(--color-font-light);
  background-color: var(--color-background-dark);

  @extend %text-body-extra-small;
}

.settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-font-secondary);
  flex: 0 0 auto;
}

.setting {
  & > button,
  & > svg {
    height: 20px;
    width: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 4px;
}

.title {
  font-size: 21px;

  @extend %text-heading-medium;

  & > span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    @extend %text-ellipsis;
  }
}

.description {
  color: var(--color-font-secondary);
  flex: 1 1 auto;

  @extend %text-body-large;

  & > span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    @extend %text-ellipsis;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  align-items: center;
  color: var(--color-font-secondary);

  gap: 8px;

  @extend %text-body-small;
}

.keywordsDataSource {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > svg {
    height: 20px;
    width: 20px;
  }
}

.location {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(256, 256, 256, 0.9);
  border-radius: 20px;
  z-index: 1;
  cursor: auto;
}
