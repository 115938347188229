@import "src/styles/textEllipsis";
@import "src/styles/iconSize";

.checkboxCellWrapper {
  padding: 0;
}

.checkboxCell {
  line-height: 46px;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
  gap: 5px;
  margin-left: 16px;

  &.group {
    margin-left: 0;
  }
}

.labelWrapper {
  max-width: 100%;
  width: auto;
  min-width: 0;

  &.group {
    font-weight: 500;
  }

  @extend %text-ellipsis;
}

.duplicatedKeywordIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;

  @include icon-size(20px);
}
