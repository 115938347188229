.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1 1;
  justify-content: center;
  background-color: var(--color-background-dark);
  color: var(--color-white);
}
