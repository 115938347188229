@import "/src/styles/textStyles.scss";

.passwordReset {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 568px;
  padding: 24px;

  gap: 20px;
}

.title {
  @extend %text-heading-large;
}

.note {
  color: var(--color-font-secondary);

  @extend %text-body-medium;
}

.link {
  font-weight: 500;

  @extend %text-body-medium;
}
