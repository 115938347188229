@import "src/styles/textStyles";

.preloader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 10px;

  &_global {
    position: absolute;
    z-index: 15;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &_inner {
    height: 100%;
  }

  & > span {
    @extend %text-body-medium;
  }
}
