@import "src/styles/form";
@import "src/styles/iconSize";
@import "src/styles/textStyles";

.formWrapper {
  max-width: 600px;

  gap: 0;
}

.checkboxWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 5px;

  @extend %text-body-medium;
}

.checkbox {
  @include icon-size(22.5px);
}
