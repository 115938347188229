@import "src/styles/iconSize";
@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;

  &::after {
    content: attr(data-limit);
    display: flex;
    justify-content: flex-end;
    height: 20px;
    color: var(--color-font-secondary);

    @extend %text-body-small;
  }
}

.limitWrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  right: 0;
  bottom: 0;
  color: var(--color-font-secondary);

  gap: 10px;

  @extend %text-body-small;

  &:empty {
    display: none;
  }
}

.limit {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  & > svg {
    @include icon-size(15px);
  }
}
