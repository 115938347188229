@import "src/styles/form";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;

  gap: 24px;
}
