$buttonShadow: 2px 2px 6px var(--color-box-shadow-hover);

@mixin filled-button-styles(
  $bgColor,
  $hoverBgColor,
  $fontColor: var(--color-font-light)
) {
  color: $fontColor;
  border-color: $bgColor;
  background-color: $bgColor;

  &:hover {
    box-shadow: $buttonShadow;
  }

  &:hover,
  &:focus {
    border-color: $hoverBgColor;
    background-color: $hoverBgColor;
  }
}
