@import "src/styles/textStyles";

.wrapper {
  max-width: 100%;
  height: auto;
  border-color: var(--color-border-light);
  padding: 8px 16px;

  &Active {
    border-color: var(--color-background-dark);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 18px;

  @extend %text-body-medium;
}

.description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  color: var(--color-font-secondary);
  line-height: 16px;

  @extend %text-body-small;
}
