@import "src/styles/textStyles";

.wrapper {
  width: 100%;
  height: 100%;
}

.tooltipWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0;

  gap: 10px;
}

.keyword {
  @extend %text-heading-small;
}

.barChartWrapper {
  padding-right: 30px;
}
