.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 10px;
}

.trackersWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 10px;
}
