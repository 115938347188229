@import "src/styles/spinAnimation";

.formButtons {
  display: flex;

  gap: 15px;

  & > button {
    min-width: 150px;
  }
}

.loader {
  @include spin-animation(1s);
}
