@import "src/styles/screenSizes";
@import "src/styles/scrollbar";
@import "src/styles/iconSize";

$sideBarWidth: 570px;

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.4);
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
  width: 40px;
  height: 40px;

  & > svg {
    transform: rotate(45deg);

    @include icon-size(20px);
  }
}

.content {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-secondary-light);
  overflow: auto;

  gap: 15px;

  @extend %scrollbar;
}
