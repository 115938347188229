@mixin typography(
  $fontWeight,
  $fontSize,
  $lineHeight
) {
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}

// --- TEXT BODY --->
%text-body-extra-large {
  @include typography(400, 20px, 27px);
}

%text-body-large {
  @include typography(400, 16px, 25px);
}

%text-body-medium {
  @include typography(400, 14px, 23px);
}

%text-body-small {
  @include typography(400, 12px, 20px);
}

%text-body-extra-small {
  @include typography(400, 10px, 18px);
}
// <--- TEXT BODY ---

// --- TEXT HEADING --->
%text-heading-extra-large {
  @include typography(500, 44px, 50px);
}

%text-heading-large {
  @include typography(500, 30px, 35px);
}

%text-heading-medium {
  @include typography(500, 18px, 24px);
}

%text-heading-small {
  @include typography(500, 16px, 22px);
}

%text-heading-extra-small{
  @include typography(500, 14px, 21px);
}
// <--- TEXT HEADING ---

// --- LABEL --->
%text-label-thin-small {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

%text-label-thin-large {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

%text-label-thick-small {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

%text-label-thick-large {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
// <--- LABEL ---

// --- LINK --->
%text-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
// <--- LINK ---
