@import "src/styles/textStyles";

.wrapper {
  cursor: pointer;

  text {
    opacity: 0;
    color: var(--color-font-secondary);
  }

  &:hover text {
    opacity: 1;
  }

  &Active text {
    opacity: 1;
    color: var(--color-font-dark);
  }
}

.label {
  @extend %text-body-small;
}
