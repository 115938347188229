.rs-table {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.rs-table-row {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 36px;
}
.rs-table-row.virtualized {
  pointer-events: none;
}
@media not all and (min-resolution: .001dpcm) {
  .rs-table-row.virtualized {
    visibility: hidden;
  }
}
.rs-table-row-expanded {
  position: absolute;
  z-index: 4;
  bottom: 0;
  width: 100%;
  height: 46px;
  padding: 10px;
  border-top: 1px solid #f2f2f5;
  border-top: 1px solid var(--rs-border-secondary);
  background-color: var(--rs-bg-card);
}
.rs-table-header-row-wrapper {
  position: relative;
  z-index: 2;
}
.rs-table-affix-header {
  z-index: 3;
  visibility: hidden;
}
.rs-table-affix-header.fixed {
  visibility: visible;
}
.rs-table-body-row-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.rs-table-body-info {
  line-height: 40px;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -20px;
  text-align: center;
}
.rs-table-body-info .icon {
  margin: 0 10px;
}
.rs-table-body-info-wheel-area {
  width: 100%;
}
.rs-table-body-wheel-area {
  width: 100%;
}
.rs-table-loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
  pointer-events: none;
  opacity: 0;
  background-color: var(--rs-loader-backdrop);
}
@media not all and (min-resolution: .001dpcm) {
  .rs-table-loader-wrapper {
    visibility: hidden;
  }
}
.rs-table-loader {
  line-height: 40px;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -20px;
  text-align: center;
}
.rs-table-loader-icon {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 12px;
  padding-top: 3px;
}
.rs-table-loader-icon::before,
.rs-table-loader-icon::after {
  width: 18px;
  height: 18px;
}
.rs-table-loader-icon::before,
.rs-table-loader-icon::after {
  position: absolute;
  right: 0;
  left: 0;
  display: block;
  content: "";
  border-radius: 50%;
}
.rs-table-loader-icon::before {
  border: 3px solid var(--rs-loader-ring);
}
.rs-table-loader-icon::after {
  -webkit-animation: loaderSpin .6s infinite linear;
  animation: loaderSpin .6s infinite linear;
  border-width: 3px;
  border-style: solid;
  border-color: var(--rs-loader-rotor) transparent transparent;
}
.rs-table-loading .rs-table-loader-wrapper {
  z-index: 1;
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
}
.rs-table-cell {
  position: absolute;
  display: block;
  overflow: hidden;
  height: 36px;
  white-space: normal;
  border-bottom: 1px solid #f2f2f5;
  border-bottom: 1px solid var(--rs-border-secondary);
  background-color: var(--rs-bg-card);
}
.rs-table-cell.first {
  border-left-width: 0;
}
.rs-table-cell-wrap1 {
  display: table;
}
.rs-table-cell-wrap2 {
  display: table-row;
}
.rs-table-cell-wrap3 {
  display: table-cell;
  vertical-align: middle;
}
.rs-table-cell-content {
  overflow: hidden;
  width: 100%;
  padding: 13px 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 25px;
  flex-wrap: nowrap !important;
}
.rs-table-cell-header .rs-table-cell-content {
  line-height: 1.66666667;
  padding: 10px 10px;
}
.rs-table-cell-header .rs-table-cell-first .rs-table-cell-content {
  display: flex;
  align-items: center;
  margin-left: 16px;

  gap: 5px;
}
.rs-table-cell-header-sort-wrapper {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}
.rs-table-cell-header-icon-sort {
  font-size: 16px;
  line-height: 1.66666667;
  color: var(--rs-table-sort);
}
.rs-table-cell-rowspan {
  border-bottom: 1px solid #f2f2f5 !important;
  border-bottom: 1px solid var(--rs-border-secondary) !important;
}
.rs-table-cell-full-text:hover {
  z-index: 1 !important;
  width: auto !important;
  -webkit-box-shadow: inset var(--rs-primary-500) 0 0 2px;
  box-shadow: inset var(--rs-primary-500) 0 0 2px;
}
.rs-table-cell-full-text:hover .rs-table-cell-content {
  width: auto !important;
}
.rs-table-cell-header-sortable .rs-table-cell-content {
  cursor: pointer;
}
.rs-table-column-resize-spanner {
  position: absolute;
  z-index: 3;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 3px;
  height: 36px;
  cursor: ew-resize !important;
  outline: none;
}
.rs-table-column-resize-spanner::after,
.rs-table-column-resize-spanner::before {
  position: absolute;
  top: 0;
  bottom: 0;
  display: none;
  width: 0;
  height: 0;
  margin: auto;
  content: " ";
}
.rs-table-column-resize-spanner::before {
  right: 4px;
  border-width: 3px;
  border-style: dashed solid dashed dashed;
  border-color: transparent var(--rs-table-resize) transparent transparent;
}
.rs-table-column-resize-spanner::after {
  left: 4px;
  border-width: 3px;
  border-style: dashed dashed dashed solid;
  border-color: transparent transparent transparent var(--rs-table-resize);
}
.rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner,
.rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover {
  background-color: var(--rs-table-resize);
}
.rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner::before,
.rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover::before,
.rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner::after,
.rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover::after {
  display: block;
}
.rs-table-cell-group-fixed-right,
.rs-table-cell-group-fixed-left {
  position: absolute;
  z-index: 4;
  background-color: var(--rs-bg-card);
}
.rs-table-cell-group-shadow,
.rs-table-cell-group-left-shadow {
  -webkit-box-shadow: 3px 0 5px var(--rs-table-shadow) /* rtl:ignore */;
  box-shadow: 3px 0 5px var(--rs-table-shadow) /* rtl:ignore */;
}
.rs-table-cell-group-right-shadow {
  -webkit-box-shadow: -3px 0 5px var(--rs-table-shadow) /* rtl:ignore */;
  box-shadow: -3px 0 5px var(--rs-table-shadow) /* rtl:ignore */;
}
.rs-table-mouse-area {
  position: absolute;
  z-index: 6;
  top: 0;
  left: -1px;
  display: none;
  width: 1px;
  background-color: var(--rs-table-resize);
}
.rs-table-mouse-area > span {
  position: absolute;
  z-index: 3;
  left: -1.5px;
  width: 3px;
  height: 36px;
  cursor: ew-resize !important;
  outline: none;
  background-color: var(--rs-table-resize);
}
.rs-table-word-wrap .rs-table-cell-content {
  white-space: normal;
}
.rs-table-bordered {
  border: 1px solid var(--rs-border-secondary);
}
.rs-table-cell-bordered .rs-table-cell {
  border-right: 1px solid var(--rs-border-secondary);
}
.rs-table-column-group {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
}
.rs-table-column-group-header {
  position: absolute;
  width: 100%;
  border-bottom: 1px solid #f2f2f5;
  border-bottom: 1px solid var(--rs-border-secondary);
}
.rs-table-column-group-header-content {
  display: table-cell;
  padding: 10px;
}
.rs-table-column-group-cell {
  position: absolute;
  border-right: 1px solid #f2f2f5;
  border-right: 1px solid var(--rs-border-secondary);
}
.rs-table-cell-expand-wrapper {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.rs-table-cell-expand-icon {
  font-size: 16px;
  cursor: pointer;
  outline: none;
}
.rs-table-scrollbar {
  position: absolute;
  z-index: 1;
  -webkit-transition: background .1s linear, -webkit-transform .1s linear;
  transition: background .1s linear, transform .1s linear, -webkit-transform .1s linear;
  opacity: .6;
  background-color: var(--rs-table-scrollbar-track);
}
.rs-table-scrollbar-hide {
  display: none;
}
.rs-table-scrollbar-handle {
  position: absolute;
  border-radius: 4px;
  background-color: var(--rs-table-scrollbar-thumb);
}
.rs-table-scrollbar-pressed {
  z-index: 1;
}
.rs-table-scrollbar-pressed .rs-table-scrollbar-handle {
  background-color: var(--rs-table-scrollbar-thumb-active);
}
.rs-table-scrollbar-horizontal {
  bottom: 0;
  width: 100%;
  height: 10px;
}
.rs-table-scrollbar-horizontal.fixed {
  position: fixed;
}
.rs-table-scrollbar-horizontal .rs-table-scrollbar-handle {
  top: 1px;
  left: 0 /* rtl:ignore */;
  height: 8px;
}
.rs-table-scrollbar-pressed.rs-table-scrollbar-horizontal:hover,
.rs-table-scrollbar-horizontal:hover {
  -webkit-transform: scaleY(1.3);
  transform: scaleY(1.3);
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}
.rs-table-scrollbar-vertical {
  top: 0;
  right: 0;
  bottom: 10px;
  width: 10px;
  background-color: var(--rs-table-scrollbar-vertical-track);
}
.rs-table-scrollbar-vertical .rs-table-scrollbar-handle {
  top: 0;
  left: 1px;
  width: 8px;
  min-height: 20px;
}
.rs-table-scrollbar-pressed.rs-table-scrollbar-vertical:hover,
.rs-table-scrollbar-vertical:hover {
  -webkit-transform: scaleX(1.3);
  transform: scaleX(1.3);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

/** Pagination**/

.rs-pagination-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-align: center;
}
.rs-pagination-group-lg {
  font-size: 16px;
}
.rs-pagination-group-md,
.rs-pagination-group-sm {
  font-size: 14px;
}
.rs-pagination-group-xs {
  font-size: 12px;
}
.rs-pagination-group-grow {
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.rs-pagination-group .rs-pagination {
  vertical-align: middle;
}
.rs-pagination-group-limit + .rs-pagination-group-total {
  margin-left: 18px;
}
.rs-pagination-group-skip {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}
.rs-pagination-group-skip .rs-input {
  display: inline-block;
  width: 46px;
  margin: 0 5px;
}
.rs-pagination {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 0;
}
.rs-pagination-lg .rs-pagination-btn {
  font-size: 16px;
  line-height: 22px;
  min-width: 42px;
  padding: 10px 16px;
}
.rs-btn-ghost.rs-pagination-lg .rs-pagination-btn {
  padding: 9px 15px;
}
.rs-btn-icon.rs-pagination-lg .rs-pagination-btn {
  line-height: 20px;
  padding: 11px 11px;
}
.rs-btn-icon.rs-pagination-lg .rs-pagination-btn > .rs-icon {
  font-size: 20px;
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn {
  line-height: 22px;
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn > .rs-icon {
  width: 42px;
  height: 42px;
  padding: 11px 11px;
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 10px 16px 10px 58px;
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 10px 58px 10px 16px;
}
.rs-btn-icon-with-text.rs-pagination-lg .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-lg .rs-pagination-btn .rs-pagination-symbol {
  height: 22px;
}
.rs-pagination-lg .rs-pagination-btn-active {
  padding: 9px 15px;
}
.rs-pagination-md .rs-pagination-btn {
  font-size: 14px;
  line-height: 20px;
  min-width: 36px;
  padding: 8px 12px;
}
.rs-btn-ghost.rs-pagination-md .rs-pagination-btn {
  padding: 7px 11px;
}
.rs-btn-icon.rs-pagination-md .rs-pagination-btn {
  line-height: 16px;
  padding: 10px 10px;
}
.rs-btn-icon.rs-pagination-md .rs-pagination-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn > .rs-icon {
  width: 36px;
  height: 36px;
  padding: 10px 10px;
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-pagination-md .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-md .rs-pagination-btn-active {
  padding: 7px 11px;
}
.rs-pagination-sm .rs-pagination-btn {
  font-size: 14px;
  line-height: 20px;
  min-width: 30px;
  padding: 5px 10px;
}
.rs-btn-ghost.rs-pagination-sm .rs-pagination-btn {
  padding: 4px 9px;
}
.rs-btn-icon.rs-pagination-sm .rs-pagination-btn {
  line-height: 16px;
  padding: 7px 7px;
}
.rs-btn-icon.rs-pagination-sm .rs-pagination-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn > .rs-icon {
  width: 30px;
  height: 30px;
  padding: 7px 7px;
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-pagination-sm .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-sm .rs-pagination-btn-active {
  padding: 4px 9px;
}
.rs-pagination-xs .rs-pagination-btn {
  font-size: 12px;
  line-height: 20px;
  min-width: 24px;
  padding: 2px 8px;
}
.rs-btn-ghost.rs-pagination-xs .rs-pagination-btn {
  padding: 1px 7px;
}
.rs-btn-icon.rs-pagination-xs .rs-pagination-btn {
  line-height: 12px;
  padding: 6px 6px;
}
.rs-btn-icon.rs-pagination-xs .rs-pagination-btn > .rs-icon {
  font-size: 12px;
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn > .rs-icon {
  width: 24px;
  height: 24px;
  padding: 6px 6px;
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 2px 8px 2px 32px;
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 2px 32px 2px 8px;
}
.rs-btn-icon-with-text.rs-pagination-xs .rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-xs .rs-pagination-btn-active {
  padding: 1px 7px;
}
.rs-pagination-btn {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  position: relative;
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: left;
  overflow: hidden;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 2px 0;
  padding: 5px 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: color .2s linear, background-color .3s linear;
  transition: color .2s linear, background-color .3s linear;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  color: var(--rs-btn-subtle-text);
  border: none;
  border: var(--rs-btn-default-border, none);
  border: none;
  border-radius: 6px;
  background-color: transparent;
}
.rs-theme-high-contrast .rs-pagination-btn {
  -webkit-transition: none;
  transition: none;
}
.rs-btn-ghost.rs-pagination-btn {
  padding: 7px 11px;
}
.rs-btn-icon.rs-pagination-btn {
  line-height: 16px;
  padding: 10px 10px;
}
.rs-btn-icon.rs-pagination-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-pagination-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-pagination-btn > .rs-icon {
  width: 36px;
  height: 36px;
  padding: 10px 10px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-btn:focus-visible {
  outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-pagination-btn:focus-visible {
  outline-offset: 2px;
}
.rs-pagination-btn:hover,
.rs-pagination-btn:focus {
  text-decoration: none;
  color: var(--rs-btn-default-hover-text);
  background-color: var(--rs-btn-default-hover-bg);
}
.rs-pagination-btn:active,
.rs-pagination-btn.rs-btn-active {
  color: var(--rs-btn-default-active-text);
  background-color: var(--rs-btn-default-active-bg);
}
.rs-pagination-btn:disabled,
.rs-pagination-btn.rs-btn-disabled {
  cursor: not-allowed;
  color: var(--rs-btn-default-disabled-text);
  background-color: var(--rs-btn-default-disabled-bg);
}
.rs-theme-high-contrast .rs-pagination-btn:disabled,
.rs-theme-high-contrast .rs-pagination-btn.rs-btn-disabled {
  opacity: .5;
}
/*@media not all and (min-resolution: .001dpcm) {*/
/*  .rs-pagination-btn {*/
/*    !* stylelint-disable *!*/
/*    -webkit-mask-image: -webkit-radial-gradient(white, black);*/
/*    !* stylelint-enable *!*/
/*  }*/
/*}*/
.rs-picker-default .rs-pagination-btn {
  -webkit-transition: border-color ease-in-out .3s;
  transition: border-color ease-in-out .3s;
}
.rs-theme-high-contrast .rs-picker-default .rs-pagination-btn {
  -webkit-transition: none;
  transition: none;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-pagination-btn-active {
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-pagination-btn {
  -webkit-transition: none;
  transition: none;
  color: var(--rs-btn-subtle-text);
  border: none;
  background-color: transparent;
}
.rs-picker-subtle .rs-pagination-btn:hover,
.rs-picker-subtle .rs-pagination-btn:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-picker-subtle .rs-pagination-btn:active,
.rs-picker-subtle .rs-pagination-btn.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-picker-subtle .rs-pagination-btn:disabled,
.rs-picker-subtle .rs-pagination-btn.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-picker-subtle .rs-pagination-btn:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-pagination-btn.rs-btn-disabled {
  opacity: .5;
}
.rs-picker-subtle .rs-pagination-btn-active {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-pagination-btn:hover,
.rs-pagination-btn:focus {
  color: var(--rs-btn-subtle-hover-text);
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-pagination-btn:active,
.rs-pagination-btn.rs-btn-active {
  color: var(--rs-btn-subtle-active-text);
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-pagination-btn:disabled,
.rs-pagination-btn.rs-btn-disabled {
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-pagination-btn:disabled,
.rs-theme-high-contrast .rs-pagination-btn.rs-btn-disabled {
  opacity: .5;
}
.rs-btn-ghost.rs-pagination-btn {
  padding: 4px 9px;
}
.rs-btn-icon.rs-pagination-btn {
  line-height: 16px;
  padding: 7px 7px;
}
.rs-btn-icon.rs-pagination-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-pagination-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-pagination-btn > .rs-icon {
  width: 30px;
  height: 30px;
  padding: 7px 7px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-pagination-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-pagination-btn .rs-pagination-symbol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-align: center;
  height: 20px;
}
.rs-theme-high-contrast .rs-pagination-btn {
  -webkit-transition: none;
  transition: none;
  color: var(--rs-pagination-item-text);
}
.rs-theme-high-contrast .rs-pagination-btn:hover,
.rs-theme-high-contrast .rs-pagination-btn:focus-visible {
  text-decoration: underline;
  color: var(--rs-pagination-item-current-text);
}
.rs-pagination-btn.rs-pagination-btn-active {
  color: var(--rs-btn-ghost-text);
  border: 1px solid var(--rs-btn-ghost-border);
  background-color: transparent;
}
.rs-pagination-btn.rs-pagination-btn-active:hover,
.rs-pagination-btn.rs-pagination-btn-active:focus {
  color: var(--rs-btn-ghost-hover-text);
  border-color: var(--rs-btn-ghost-hover-border);
  background-color: transparent;
}
.rs-pagination-btn.rs-pagination-btn-active:active,
.rs-pagination-btn.rs-pagination-btn-active.rs-btn-active {
  color: var(--rs-btn-ghost-active-text);
  border-color: var(--rs-btn-ghost-active-border);
  background-color: transparent;
}
.rs-pagination-btn.rs-pagination-btn-active:disabled,
.rs-pagination-btn.rs-pagination-btn-active.rs-btn-disabled {
  opacity: .3;
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
}
.rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active:disabled,
.rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active.rs-btn-disabled {
  opacity: .5;
}
.rs-theme-high-contrast .rs-pagination-btn.rs-pagination-btn-active {
  text-decoration: underline;
  color: var(--rs-pagination-item-current-text);
}
