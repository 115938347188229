.itemToggler {
  flex: 0 0 auto;
  cursor: pointer;
  transition: transform .2s;
  border-radius: 40px;
  background-color: #ededed;

  &Collapsed {
    transform: rotate(90deg);
  }
}
