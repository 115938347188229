@import "src/styles/textStyles";
@import "src/styles/screenSizes";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;

  gap: 16px;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 8px;
}

.subheading {
  @extend %text-heading-medium;
}

.searchesWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 100%;
  background-color: var(--color-background-light);

  & > *:not(:first-child) {
    padding-top: 24px;
  }

  & > *:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--color-border-light);
  }
}

.searches {
  position: relative;
  border-radius: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 24px;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;

  gap: 4px;
}

.submitButton {
  min-width: 200px;

  gap: 8px;

  @extend %text-body-large;
}

@media screen and (max-width: $mobileScreen) {
  .selectedSearchesSection {
    flex: 1 1 auto;
  }
}
