@mixin spin-animation($speed: 2s) {
  -webkit-animation: spin $speed linear infinite;
  -moz-animation: spin $speed linear infinite;
  animation: spin $speed linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}
