@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.text {
  color: var(--color-font-disabled);

  @extend %text-body-medium;
}
