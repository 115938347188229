@import "src/styles/spinAnimation";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loader {
  @include spin-animation(1s);
}
