@import "src/styles/iconSize";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;
}

.locked {
  pointer-events: none;
  filter: blur(2px);
}

.button {
  position: relative;
  padding: 0;
  border-radius: 4px;
  height: auto;

  svg {
    @include icon-size(24px);
  }
}
