@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";
@import "src/styles/spinAnimation";

.modalWrapper {
  max-width: 500px;
  min-width: 100px;
  width: 100vw;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 0;

  gap: 15px;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 5px;

  & > span:first-child {
    @extend %text-heading-large;
  }

  & > span:last-child {
    font-weight: 400;

    @extend %text-heading-medium;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 15px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;

  gap: 5px;

  & > .label {
    text-transform: uppercase;
    color: var(--color-font-disabled);

    @extend %text-body-small;
  }

  & > .value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 5px;
  }

  &:last-of-type {
    margin: 20px auto 0;
  }
}

.dashboardDateRanges {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  gap: 35px;
}

.dashboardDateRange {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;

  gap: 5px;
}

.dashboardDateRangeLabel {
  display: flex;
  flex-direction: column;

  & > .dashboardDateRangeSubLabel {
    color: var(--color-font-disabled);

    @extend %text-body-small;
  }
}

.checkbox {
  height: 25px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 0;

  gap: 15px;
}

.text {
  width: 100%;
  text-align: start;
  color: var(--color-font-disabled);

  @extend %text-body-small;
}

.loader {
  @include spin-animation(1s);
}

@media screen and (max-width: $mobileScreen) {
  .accessTypeSection {
    display: none;
  }

  .dateRangesSection {
    min-height: 0;
    overflow: auto;
    justify-content: flex-start;
  }

  .text {
    display: none;
  }
}
