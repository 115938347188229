@import "src/styles/textStyles";
@import "src/styles/iconSize";

.tableCardHead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px 20px;

  gap: 10px;

  @extend %text-heading-small;
}

.subTableCardHead {
  padding: 0 30px 15px 45px;
}

.tableCardHeadTitle {
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;

  & > span {
    display: flex;
    align-items: center;

    gap: 5px;

    svg {
      flex: 0 0 auto;

      @include icon-size(20px);
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.tableCardHeadAction {
  display: flex;
  align-items: center;
  margin-left: auto;

  gap: 10px;

  & > * {
    position: static;
  }
}

.tableCardHeadMenu {
  position: relative;
}
