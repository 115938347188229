@import "src/styles/textStyles";

.tableHead {
  width: 100%;

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th {
    padding: 20px 15px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-bottom: 1px solid var(--color-border-light);

    @extend %text-heading-small;

    &.sortable {
      position: relative;
      cursor: pointer;
      user-select: none;
      transition: transition 1s;
    }
  }
}

.shortTableHead {
  display: table;
  width: 100%;
  table-layout: fixed;
}
