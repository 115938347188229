@import "/src/styles/form";
@import "/src/styles/textStyles";
@import "/src/styles/screenSizes";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 30px;
}

.formWrapper {
  gap: 25px;
}

.inputsWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 20px;
}

.inputWrapperFull {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;

  gap: 10px;
}

.input {
  display: flex;
  width: 100%;
  color: var(--color-font-dark);
}

.selectInput {
  color: var(--color-font-dark);
}

.button {
  width: 250px;
  margin: auto;
}

.selectedCountryWarning {
  color: var(--color-orange);

  @extend %text-body-extra-small;
}
