@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 1000px;
  padding: 60px 30px 15px;
  height: 100%;

  gap: 20px;
}

.button {
  min-width: 150px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 15px;

  @extend %text-body-large;
}

.subscriptionPlansWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  height: 0;
  min-height: 300px;
  overflow: auto;

  gap: 20px;
}

.subscriptionPlan {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  width: 300px;
  height: 100%;
  padding: 20px;
  background-color: var(--color-background-light);
  border: 1px solid var(--color-border-light);
  border-radius: 8px;

  gap: 10px;

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }
}

.subscriptionPlanTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 10px;
}

.subscriptionPlanName {
  text-transform: uppercase;

  @extend %text-heading-medium;
}

.subscriptionPlanPrice {
  text-align: center;

  @extend %text-heading-medium;
}

.subscriptionPlanPriceCustom {
  text-align: center;

  @extend %text-body-large;
}

.subscriptionPlanPriceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60px;

  gap: 0;
}

.subscriptionPlanPriceSubtitle {
  color: var(--color-grey-secondary-light);

  @extend %text-body-medium;
}
