@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
}

.name {
  @extend %text-heading-large;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 15px;

  & > div {
    flex: 1 1 auto;
  }
}

.info {
  flex: 0 0 auto;
  color: var(--color-font-secondary);

  @extend %text-body-medium;
}

.button {
  margin: 0 0 0 auto;
}
