@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/scrollbar";

.widgetWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 0;

  gap: 20px;

  @extend %scrollbar;
}

.widgetSubHead {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;

  gap: 10px;
}

.widgetSubHeadSettings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 2 1;
  min-width: 240px;

  gap: 10px;
}

.searchWrapper {
  min-width: 240px;
  width: auto;
  flex: 1 1;
}

.widgetSearch {
  flex: 1;
}

.filterButton {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: none;
  outline: 1px solid var(--color-border-dark);

  svg {
    @include icon-size(20px);
  }
}

.filtersCounter {
  position: absolute;
  top: -3px;
  right: -5px;
  color: var(--color-font-light);
  background-color: var(--color-background-dark);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 50%;
  margin: 0;
  line-height: 15px;
  font-size: 10px;

  @include icon-size(15px);
}

.totalArticles {
  flex: 1 1;

  @extend %text-body-medium;
}

.articlesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 0;
  margin-top: auto;

  gap: 15px;
}

.articles {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  margin-bottom: auto;

  gap: 15px;

  @extend %scrollbar;
}

.emptyArticlesList {
  color: var(--color-font-disabled);

  @extend %text-body-medium;
}

.textBold {
  @extend %text-heading-extra-small;
}

.loader {
  height: 100%;
}
