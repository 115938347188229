@import "src/styles/screenSizes";
@import "src/styles/buttonEffect";
@import "src/styles/scrollbar";

%option {
  line-height: 40px;
  overflow: hidden;
  height: 40px;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selectWithFilter {
  font-size: 14px;
  position: relative;
  height: 40px;
}

.inputWrapper {
  position: relative;
  cursor: pointer;

  &Disabled {
    cursor: not-allowed;
    opacity: .5;
  }
}

.input {
  overflow: hidden;
  padding: 0 30px 0 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;

  &:focus {
    overflow: hidden;
  }
}

.triangle {
  position: absolute;
  top: 50%;
  right: 15px;
  transition: all .2s;
  transform: translateY(-50%);

  &.Open {
    transform: translateY(-50%) rotate(180deg);
  }
}

.popup {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  min-width: min-content;
  padding: 10px;
  border: 1px solid var(--color-border-light);
  border-radius: 4px;
  background-color: var(--color-background-light);
  box-shadow: var(--box-shadow-light);

  gap: 10px;

  &Up {
    bottom: 45px;
    flex-direction: column-reverse;
  }

  &Down {
    top: 45px;
    flex-direction: column;
  }
}

.options {
  overflow-y: auto;
  max-height: 202px;
  cursor: pointer;

  @extend %scrollbar;
}

.option {
  transition: all .2s;

  @extend %option;
  @extend %buttonEffect;
}

.noOptions {
  text-align: center;

  @extend %option;
}

.message {
  display: flex;
  align-items: center;
  height: 30px;
  margin: 5px 10px;
  padding: 0 7px;
  cursor: default;
  color: var(--color-font-dark);
  border: 1px solid;
  border-radius: 4px;

  gap: 7px;

  &.success {
    border-color: var(--color-notification-success-border);
    background-color: var(--color-notification-success-background);
  }

  &.info {
    border-color: var(--color-notification-info-border);
    background-color: var(--color-notification-info-background);
  }

  &.warning {
    border-color: var(--color-notification-warning-border);
    background-color: var(--color-notification-warning-background);
  }

  &.error {
    border-color: var(--color-notification-error-border);
    background-color: var(--color-notification-error-background);
  }
}
