@import "src/styles/textStyles";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 36px;

  gap: 8px;
}

.placeholder {
  margin: auto;
  color: var(--color-font-disabled);

  @extend %text-body-medium;
}

.loaderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(256, 256, 256, 0.9);
  z-index: 1;
}
