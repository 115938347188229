@import "src/styles/screenSizes";

div[class~="Toastify__toast"] {
  width: fit-content;
  min-height: auto;
  margin: 10px auto 0;
  padding: 0;
  border: 1px solid;
  border-radius: 4px;

  & > button {
    align-self: center;
    margin: 10px;
    padding: 5px 15px;
    cursor: pointer;
    color: var(--color-font-light);
    border-radius: 4px;

    &[class~="toast-button--success"] {
      background-color: var(--color-notification-success-button);
    }

    &[class~="toast-button--warning"] {
      background-color: var(--color-notification-warning-button);
    }

    &[class~="toast-button--error"] {
      background-color: var(--color-notification-error-button);
    }

    &[class~="toast-button--info"] {
      background-color: var(--color-notification-info-button);
    }
  }
}

div[class~="Toastify__toast-container"] {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  width: auto;
  min-width: 320px;
  max-width: 395px;
  margin-bottom: 30px;
  padding: 0;
  text-align: center;
}

div[class~="Toastify__toast-body"] {
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 10px;

  gap: 7px;

  div[class~="Toastify__toast-icon"] {
    width: auto;
    margin: 0;
  }

  div:last-child {
    flex: unset;
  }
}

div[class~="Toastify__toast--success"] {
  color: var(--color-notification-success-font);
  border-color: var(--color-notification-success-border);
  background-color: var(--color-notification-success-background);
}

div[class~="Toastify__toast--warning"] {
  color: var(--color-notification-warning-font);
  border-color: var(--color-notification-warning-border);
  background-color: var(--color-notification-warning-background);
}

div[class~="Toastify__toast--error"] {
  color: var(--color-notification-error-font);
  border-color: var(--color-notification-error-border);
  background-color: var(--color-notification-error-background);
}

div[class~="Toastify__toast--info"] {
  color: var(--color-notification-info-font);
  border-color: var(--color-notification-info-border);
  background-color: var(--color-notification-info-background);
}

@media screen and (max-width: $mobileScreen) {
  div[class~="Toastify__toast-container"] {
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
