@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/textEllipsis";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid var(--color-border-light);
  border-radius: 20px;
  max-width: 100%;
  width: 100%;

  gap: 8px;
}

.name {
  white-space: nowrap;

  @extend %text-heading-small;
  @extend %text-ellipsis;
}

.settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-font-secondary);

  gap: 8px;

  & > svg {
    @include icon-size(20px);
  }
}

.location,
.language {
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  @extend %text-body-medium;
}
