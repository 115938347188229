@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/selectStyles";

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  gap: 15px;
}

.optionsWrapper {
  max-height: 100%;
}

.dropdownFooterWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 15px;
}

.button {
  padding: 0;
  width: 100%;
  text-decoration: underline;

  @extend %text-body-medium;

  svg {
    @include icon-size(15px);
  }
}
