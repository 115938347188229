.wrapper {
  z-index: 0;
  display: flex;
  overflow: auto;
  flex: 1;
  flex-direction: column;
  height: 100%;
  scroll-padding-top: 160px;
}

.content {
  flex: 1 1;
  display: flex;
  margin-top: var(--header-height);
}

.initialPreloader {
  background-color: var(--color-background-light) !important;
}
