@import "src/styles/spinAnimation";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 15px;
}

.button {
  margin: 0 0 0 auto;
}

.loader {
  @include spin-animation(1s);
}
