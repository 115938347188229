@import "src/styles/selectStyles";
@import "src/styles/iconSize";
@import "src/styles/screenSizes";

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  gap: 10px 15px;
}

.inputWrapper {
  box-shadow: var(--box-shadow-light);

  input {
    border: none;
  }
}

.trackerLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  gap: 5px;

  & > span {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & > svg {
    flex: 0 0 auto;
    color: var(--color-font-secondary);

    @include icon-size(15px);
  }
}

.trackerChartIcon {
  @include icon-size(10px);
}

.trackerChartIconInactive {
  @include icon-size(10px);

  & > span {
    border: 1px solid;
    background-color: transparent !important;
  }
}

@media screen and (max-width: $mobileScreen) {
  .dropdown {
    max-width: 100%;
  }
}
