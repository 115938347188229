.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
}

.leftText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    margin-left: 4px;
    content: attr(data-optional-label);
    opacity: .6;
  }
}

.rightText {
  flex-shrink: 0;
}
