@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: column;

  gap: 15px;
}

.section {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-border-light);
  padding: 15px;

  gap: 10px;
}

.title {
  color: var(--color-font-secondary);

  @extend %text-body-large;
}
