@import "src/styles/textStyles";
@import "../../styles";

.title {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.configurations {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;

  gap: 8px;
}

.content {
  padding: 15vh 24px 40px;

  gap: 8px;
}

.section {
  gap: 4px;
}

.textareaWrapper {
  max-height: 280px;
  font-weight: 400;

  @extend %text-heading-extra-large;
}

.textarea {
  background: transparent;
  border: none;
}

@media screen and (max-width: $tabletScreen) {
  .heading {
    font-size: clamp(2rem, 3vw, 2rem);
  }

  .textarea {
    font-size: clamp(2rem, 6vw, 3rem) !important;
  }
}

@media screen and (max-width: $mobileScreen) {
  .heading {
    font-size: clamp(1rem, 4vw, 3rem);
  }

  .textarea {
    font-size: clamp(1.25rem, 5.5vw, 3.5rem) !important;
  }
}
