@import "src/styles/scrollbar";
@import "src/styles/textStyles";

.wrapper {
  width: 100vw;
  max-width: 675px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;

  gap: 24px;
}

.settingsWrapper {
  display: flex;
  flex-direction: column;

  gap: 16px;
}

.settingsTitle {
  @extend %text-heading-medium;
}

.setting {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;

  gap: 4px;

  & > div {
    width: auto;
    min-width: initial;
  }
}

.label {
  text-transform: uppercase;
  color: var(--color-font-secondary);
  font-weight: 500;

  @extend %text-body-small;
}

.settings {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  gap: 8px;
}

.configurations {
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  border-radius: 16px;
  border: 1px solid var(--color-border-light);
  overflow: auto;
  max-height: 225px;

  gap: 8px;

  @extend %scrollbar;
}

.keywordsSettings {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 4px;

  @extend %text-body-medium;
}

.button {
  @extend %text-heading-extra-small;
}
