@import "src/styles/scrollbar";
@import "src/styles/textStyles";

.wrapper {
  width: 100vw;
  max-width: 675px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;

  gap: 24px;
}

.tabsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid var(--color-border-dark);
}

.tab {
  flex: 1 1;
  padding: 8px 16px;
  text-align: center;
  color: var(--color-font-secondary);
  border-radius: 20px;

  @extend %text-body-medium;

  &.active {
    background-color: var(--color-background-dark);
    color: var(--color-font-light);
  }
}

.settingsWrapper {
  display: flex;
  flex-direction: column;

  gap: 16px;
}

.settingsTitle {
  @extend %text-heading-medium;
}

.trackersWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 16px;
}

.trackersTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 8px;

  & > span {
    @extend %text-heading-medium;
  }
}

.trackers {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 120px;
  width: 100%;

  gap: 8px;
}

.selectWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 4px;

  & > span {
    @extend %text-body-medium;
  }
}

.settings {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  gap: 8px;
}

.setting {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;

  gap: 4px;

  & > div {
    width: auto;
    min-width: initial;
  }
}

.configurations {
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  border-radius: 16px;
  border: 1px solid var(--color-border-light);
  overflow: auto;
  max-height: 125px;

  gap: 8px;

  @extend %scrollbar;
}

.divider {
  height: 1px;
  background-color: var(--color-border-light);
}

.label {
  text-transform: uppercase;
  color: var(--color-font-secondary);
  font-weight: 500;

  @extend %text-body-small;
}

.keywordsSettings {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 16px;

  @extend %text-body-medium;
}

.preserveKeywords {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
}

.button {
  @extend %text-heading-extra-small;
}

.submit {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 16px;
}

.submitButton {
  width: fit-content;
}
