.sortIcon {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-left: 2px;
  vertical-align: middle;
  border-radius: 2px;

  gap: 1.5px;

  &:hover {
    background-color: #ededed;
  }

  &Asc {
    svg {
      &:first-child {
        opacity: 1;
      }
    }
  }

  &Desc {
    svg {
      &:last-child {
        opacity: 1;
      }
    }
  }

  svg {
    width: 5px;
    height: 5px;
    opacity: .2;

    &:last-child {
      transform: rotate(180deg);
    }
  }
}
