@import "src/styles/textStyles";

.wrapper {
  max-width: 100%;
  height: auto;
  border-color: transparent;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--color-background-secondary);

  &Active {
    border-color: var(--color-border-dark);

    &:focus, &:hover {
      border-color: var(--color-border-dark);
    }

    .name {
      font-weight: 500;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 18px;

  @extend %text-body-medium;
}
