@import "../../styles";

.submitButton {
  font-size: 20px;
}

@media screen and (max-width: $mobileScreen) {
  .submitButton {
    font-size: 16px;
  }
}
