@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  gap: 15px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  @extend %text-heading-medium;
}

.copyLink {
  margin-left: auto;
}
