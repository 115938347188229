@import "src/styles/iconSize";
@import "src/styles/textStyles";

.button {
  padding: 0;
  width: 100%;

  @extend %text-body-medium;

  svg {
    @include icon-size(15px);
  }
}
