@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 15px;

  gap: 15px;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: auto 0 0;
  flex: 0 0 auto;

  gap: 10px;
}

.totalLabel {
  display: flex;
  flex-direction: row;
  margin-right: auto;

  gap: 5px;
}

.paginationItem {
  height: 25px;
  min-width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border-light);
  padding: 5px;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none !important;

  &:not(.paginationActiveItem):hover {
    background-color: var(--color-background-light);
  }

  @extend %text-body-small;
}

.paginationActiveItem {
  color: var(--color-font-light);
  background-color: var(--color-background-dark);

  &:focus {
    color: var(--color-font-light);
  }
}

.paginationBreakItem {
  cursor: pointer;
  text-decoration: none !important;
}

.paginationNavigation {
  display: flex;
  cursor: pointer;
}

.paginationDisabled {
  opacity: .4;

  & > a {
    cursor: not-allowed;
  }
}
