@import "src/styles/textStyles";
@import "../../generalStyles/generalPreviewStyles.module.scss";

.chartWrapper {
  justify-content: flex-start;
}

.footerWrapper {
  width: fit-content;
  height: 15px;
  color: var(--color-font-secondary);

  @extend %text-body-medium;
}
