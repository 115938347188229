@import "src/styles/textStyles";

.wrapper {
  position: relative;
}

.updatedWrapper {
  width: 200px;

  :global(.rs-picker-toggle) {
    width: 100%;
    border-radius: 20px !important;
  }
}

.error {
  position: absolute;
  color: var(--color-red);
  bottom: -24px;
  text-wrap: nowrap;

  @extend %text-body-large;
}
