.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 0;
  flex: 1 1 auto;
  padding: 20px 0;

  gap: 20px;
}

.sectionsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 10px;
  height: 0;
  width: 100%;
  flex: 1 1 auto;
  overflow: auto;

  gap: 15px;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  & > svg {
    flex: 0 0 auto;
  }
}
