@import "src/styles/textStyles";
@import "src/styles/iconSize";
@import "src/styles/spinAnimation";

.wrapper {
  width: 570px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 60px 30px;
  background-color: var(--color-background-secondary-light);

  gap: 15px;
}

.title {
  @extend %text-heading-medium;
}

.tip {
  display: flex;
  flex-direction: row;
  color: var(--color-font-secondary);

  gap: 5px;

  @extend %text-body-medium;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;

  gap: 15px;

  @extend %text-label-thick-large;
}

.updateButton {
  min-width: 180px;
}

.loader {
  @include spin-animation(1s);
}
