@import "src/styles/form";

.alertWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  margin: 10px 0 0;
}
