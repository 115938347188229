@import "src/styles/textStyles";
@import "src/styles/textEllipsis";
@import "src/styles/screenSizes";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 20px;
  padding: 12px 16px;
  border: 1px solid var(--color-brand);
  background-color: var(--color-background-light);
  width: 100%;
  min-width: 0;

  gap: 8px;
}

.heading {
  padding: 4px 12px;
  line-height: 12px;
  border-radius: 30px;
  color: var(--color-font-dark);
  background-color: var(--color-brand);

  @extend %text-body-extra-small;
}

.settings {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-font-secondary);
  margin-left: auto;
  min-width: 0;
  flex: 0 0 auto;

  gap: 8px;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  gap: 12px;
}

.title {
  width: auto;
  white-space: nowrap;

  @extend %text-ellipsis;
  @extend %text-heading-medium;
}

.description {
  width: auto;
  color: var(--color-font-secondary);
  white-space: nowrap;
  flex: 1 1 50px;

  @extend %text-ellipsis;
  @extend %text-body-large;
}

.keywordsDataSource {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > svg {
    height: 20px;
    width: 20px;
  }
}

.location,
.language {
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  @extend %text-body-medium;
}

.language {
  flex: 0 0 auto;
}

@media screen and (max-width: $mobileScreen) {
  .wrapper {
    flex-wrap: wrap;
    padding: 12px 18px;
  }

  .description {
    flex: 0 0 100%;
    order: 2;
  }

  .settings {
    margin-left: 0;
  }
}
