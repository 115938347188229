@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";
@import "src/styles/spinAnimation";

$containerWidth: MIN(33%, 300px);

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  z-index: 10;

  gap: 15px;
}

.headWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  gap: 20px;

  &:before {
    content: "";
    width: $containerWidth;
  }
}

.nameWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 2 1;
  max-width: 100%;
  min-width: 0;

  gap: 8px;
}

.name {
  overflow: hidden;
  margin: 0;
  min-width: 0;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;

  @extend %text-heading-extra-large;
}

.infoWrapper {
  position: absolute;
  left: 20px;
  top: 66px;
  height: 40px;
}

.lockIcon {
  color: var(--color-font-secondary);

  @include icon-size(20px);
}

@media screen and (max-width: $tabletScreen) {
  .nameWrapper {
    flex: 15 1;
  }

  .headWrapper:before {
    flex: 1 1;
  }
}

@media screen and (max-width: $mobileScreen) {
  .infoWrapper {
    position: initial;
  }
}
