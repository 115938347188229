@import "src/styles/textStyles";

.wrapper {
  pointer-events: none;
}

.label {
  fill: var(--color-grey-secondary);
  opacity: 0.25;

  @extend %text-body-small;
}
