@import "src/styles/textStyles";
@import "src/styles/screenSizes";

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  min-height: 0;
  background-color: var(--color-background-light);

  gap: 8px;

  & > *:not(:first-child) {
    padding-top: 24px;
  }

  & > *:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--color-border-light);
  }
}

.heading {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  gap: 16px;

  @extend %text-heading-medium;
}

.button {
  font-weight: 400;
  border-color: var(--color-border-light);

  @extend %text-body-medium;
}

.searches {
  border-radius: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow: auto;

  gap: 24px;
}

@media screen and (max-width: $tabletScreen) {
  .searches {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: $mobileScreen) {
  .wrapper {
    padding: 0;

    & > *:not(:first-child) {
      padding-top: 16px;
    }

    & > *:not(:last-child) {
      padding-bottom: 16px;
    }
  }

  .searches {
    grid-template-columns: repeat(1, 1fr);
  }
}
