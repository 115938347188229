@import "src/styles/iconSize";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;

  & > span {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  @include icon-size(10px);
}
