.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10px;
  width: 100%;
  background-color: var(--color-background-secondary-dark);
  border-radius: 25px;
  overflow: hidden;
}

.progressWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: var(--color-green);
  border-radius: 25px;
  transition: width 0.5s ease-in-out;
}

.progressLabel {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.placeholderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border-radius: 25px;
}
