@import "src/styles/spinAnimation";

.tile {
  position: relative;
  width: 200px;
  height: 160px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: var(--box-shadow-light);
}

.sideBarPreReleaseWrapper {
  position: absolute;
  top: 5px;
  right: 5px;
}

.tileContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  &Blocked {
    opacity: 0.25;
  }
}

.widgetTitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.lockBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  padding: 0;

  &Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.addWidgetIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  padding: 0;
}

.dragIcon {
  position: absolute;
  top: 50%;
  left: 5px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  opacity: .5;
}

.loader {
  @include spin-animation(1s);
}
