:root {
  //#region COLORS
  --color-white: #FFFFFF;

  --color-white-secondary-extralight: #F9FAFA;
  --color-white-secondary-light: #F4F5F6;
  --color-white-secondary: #E9EBEE;
  --color-white-secondary-dark: #DCDEE1;

  --color-grey-light: #9DA7B3;
  --color-grey: #626F84;
  --color-grey-dark: #303030;

  --color-black: #000000;

  --color-brown-dark: #58151C;

  --color-grey-secondary-light: #A8AAB3;
  --color-grey-secondary: #6D7173;
  --color-grey-secondary-dark: #505254;

  --color-green-light: #E7FCE4;
  --color-green: #41B167;
  --color-green-dark: #2F985D;

  --color-green-secondary-light: #D3F4E1;
  --color-green-secondary: #81E0A8;
  --color-green-secondary-dark: #35CD74;

  --color-blue-light: #F8FBFF;
  --color-blue: #1753FF;
  --color-blue-dark: #0035CF;

  --color-blue-secondary-light: #D2D6DC;
  --color-blue-secondary: #30405F;
  --color-blue-secondary-dark: #1E314F;

  --color-orange-light: #FFFCEA;
  --color-orange: #FFB800;
  --color-orange-dark: #BE8900;

  --color-red-light: #FFE4D9;
  --color-red: #E5484D;
  --color-red-dark: #E5303C;

  --color-red-secondary: #EA5963;

  --color-pink-light: #FAD7dA;
  --color-pink: #F3B6BC;
  --color-pink-dark: #FC3838;

  --color-brand: #E3114F;
  --color-brand-dark: #D8104B;
  //#endregion COLORS

  //#region ASSIGNED COLORS
  --color-box-shadow: rgba(0, 0, 0, .16);
  --color-box-shadow-hover: rgba(31, 31, 31, .3);

  --color-background-light: var(--color-white);
  --color-background-dark: var(--color-blue-secondary-dark);
  --color-background-secondary-extralight: var(--color-white-secondary-extralight);
  --color-background-secondary-light: var(--color-white-secondary-light);
  --color-background-secondary: var(--color-white-secondary);
  --color-background-secondary-dark: var(--color-white-secondary-dark);

  --color-background-secondary-light-gradient:
    linear-gradient(117deg, var(--color-white) 1.24%, var(--color-white-secondary) 100%);

  --color-font-light: var(--color-white);
  --color-font-secondary: var(--color-grey);
  --color-font-disabled: var(--color-grey-light);
  --color-font-accent: var(--color-blue-dark);
  --color-font-dark: var(--color-blue-secondary-dark);

  --color-border-light: var(--color-blue-secondary-light);
  --color-border: var(--color-grey-secondary-light);
  --color-border-dark: var(--color-blue-secondary-dark);

  --color-button-background: var(--color-white-secondary-light);
  --color-button-background-secondary: var(--color-white-secondary);
  --color-button-background-disabled: var(--color-white-secondary-light);

  --color-notification-success-background: var(--color-green-light);
  --color-notification-success-button: var(--color-green);
  --color-notification-success-icon: var(--color-green);
  --color-notification-success-border: var(--color-green);
  --color-notification-success-font: var(--color-black);

  --color-notification-info-background: var(--color-blue-light);
  --color-notification-info-button: var(--color-blue);
  --color-notification-info-icon: var(--color-blue);
  --color-notification-info-border: var(--color-blue);
  --color-notification-info-font: var(--color-black);

  --color-notification-warning-background: var(--color-orange-light);
  --color-notification-warning-button: var(--color-orange);
  --color-notification-warning-icon: var(--color-orange);
  --color-notification-warning-border: var(--color-orange);
  --color-notification-warning-font: var(--color-black);

  --color-notification-caution-background: var(--color-pink-light);
  --color-notification-caution-button: var(--color-pink-dark);
  --color-notification-caution-icon: var(--color-pink-dark);
  --color-notification-caution-border: var(--color-pink);
  --color-notification-caution-font: var(--color-brown-dark);

  --color-notification-error-background: var(--color-red-light);
  --color-notification-error-button: var(--color-red);
  --color-notification-error-icon: var(--color-red);
  --color-notification-error-border: var(--color-red);
  --color-notification-error-font: var(--color-black);
  //#endregion ASSIGNED COLORS
}
