@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";
@import "src/styles/slideAnimation";
@import "src/styles/responsiveContainer";

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  gap: 16px;

  &WithMargin {
    margin-bottom: 64px;
  }
}

.heading {
  @extend %text-heading-large;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  padding: 40px 24px;

  gap: 16px;

  @extend %responsive-container;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  gap: 8px;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 4px;
}

.submitButton {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  gap: 8px;
}

.cancelButton {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  gap: 8px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--color-background-light);
  filter: drop-shadow(5px -4px 30px rgba(0, 0, 0, 0.06));
  width: 100%;
  position: fixed;
  bottom: -1px;
  z-index: 20;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    height: 82px;

    gap: 16px;

    @extend %responsive-container;
  }
}

.nextStep {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  gap: 8px;

  & > button > svg {
    @include icon-size(15px);
  }

  & > span:nth-of-type(1) {
    font-weight: 500;
    text-transform: uppercase;
    padding: 4px 16px;
    color: var(--color-font-dark);
    background-color: var(--color-button-background-secondary);
    border-radius: 20px;
    text-wrap: nowrap;

    @extend %text-body-small;
  }

  & > span:nth-of-type(2) {
    color: var(--color-font-disabled);
    text-wrap: nowrap;
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;

    @extend %text-heading-medium;
  }
}

.slideOutLeft {
  @extend %slide-out-left;
}

.slideInLeft {
  @extend %slide-in-left;
}

.slideInRight {
  @extend %slide-in-right;
}

.slideOutRight {
  @extend %slide-out-right;
}

.slideOutBottom {
  @extend %slide-out-bottom;
}

.slideInBottom {
  @extend %slide-in-bottom;
}

@media screen and (max-width: $mobileScreen) {
  .content {
    padding: 24px 16px;
  }

  .footer > div {
    height: 62px;
  }

  .nextStep {
    & > button > svg {
      @include icon-size(12px);
    }

    & > span:nth-of-type(1) {
      font-size: 10px;
    }

    & > span:nth-of-type(2) {
      font-size: 14px;
    }
  }

  .submitButton,
  .cancelButton {
    height: 32px;
    padding: 4px 16px;
    font-size: 16px;
    line-height: 20px;

    & > svg {
      display: none;
    }
  }
}
