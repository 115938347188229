@import "src/styles/iconSize";
@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .datePickerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    gap: 10px;

    .dateSelectorButton {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border: 1px solid var(--color-border-dark);

      @include icon-size(40px);

      svg {
        @include icon-size(20px);
      }
    }
  }

  .datePickerPopper {
    padding: 0;
    z-index: 5;
  }
}

.datePickerCalendar {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--color-background-light);
  box-shadow: var(--box-shadow-light);

  &[class~="react-datepicker"] {
    border-color: var(--color-border-light);
  }

  button[class~="react-datepicker__navigation--previous"],
  button[class~="react-datepicker__navigation--next"] {
    display: none;
  }

  div[class~="react-datepicker__triangle"] {
    display: none;
  }

  div[class~="react-datepicker__month-container"] {
    display: flex;
    flex-direction: column;
    color: var(--color-font-dark);

    gap: 15px;

    div[class~="react-datepicker__header"] {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      border: 0;
      background-color: transparent;

      gap: 10px;

      div[class~="react-datepicker__current-month"] {
        display: none;

        @extend %text-heading-medium;
      }

      div[class~="react-datepicker__month-dropdown"] {
        display: flex;
        flex-direction: column;
        background-color: var(--color-background-light);
        border-color: var(--color-border-light);

        div[class~="react-datepicker__month-option"] {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 30px;

          @extend %text-body-medium;

          &[class~="react-datepicker__month-option--selected_month"] {
            @extend %text-heading-extra-small;
          }

          &:hover {
            background-color: var(--color-background-secondary-dark);
          }

          span[class~="react-datepicker__month-option--selected"] {
            display: none;
          }
        }
      }

      div[class~="react-datepicker__month-read-view"] {
        display: flex;
        flex-direction: row;
        align-items: center;
        visibility: visible !important;

        span[class~="react-datepicker__month-read-view--down-arrow"] {
          position: absolute;
          display: inline-block;
          padding: 1px;
          border: solid var(--color-border-dark);
          border-width: 2px 2px 0 0;
          top: 6px;

          @include icon-size(8px);
        }

        span[class~="react-datepicker__month-read-view--selected-month"] {
          @extend %text-heading-medium;
        }
      }

      div[class~="react-datepicker__year-dropdown"] {
        display: flex;
        flex-direction: column;
        background-color: var(--color-background-light);
        border-color: var(--color-border-light);

        div[class~="react-datepicker__year-option"] {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 30px;

          @extend %text-body-medium;

          &[class~="react-datepicker__year-option--selected_year"] {
            @extend %text-heading-extra-small;
          }

          &:hover {
            background-color: var(--color-background-secondary-dark);
          }

          span[class~="react-datepicker__year-option--selected"] {
            display: none;
          }

          a[class~="react-datepicker__navigation--years"] {
            display: inline-block;
            padding: 1px;
            border: solid var(--color-border-dark);
            border-width: 0 2px 2px 0;

            @include icon-size(10px);

            &[class~="react-datepicker__navigation--years-upcoming"] {
              top: 3px;
              transform: rotate(-135deg);
            }

            &[class~="react-datepicker__navigation--years-previous"] {
              top: -3px;
              transform: rotate(45deg);
            }
          }
        }
      }

      div[class~="react-datepicker__year-read-view"] {
        display: flex;
        flex-direction: row;
        align-items: center;
        visibility: visible !important;

        span[class~="react-datepicker__year-read-view--down-arrow"] {
          position: absolute;
          display: inline-block;
          padding: 1px;
          border: solid var(--color-border-dark);
          border-width: 2px 2px 0 0;
          top: 6px;

          @include icon-size(8px);
        }

        span[class~="react-datepicker__year-read-view--selected-year"] {
          @extend %text-heading-medium;
        }
      }

      div[class~="react-datepicker__day-names"] {
        display: flex;
        flex-direction: row;

        gap: 10px;
      }

      div[class~="react-datepicker__day-name"] {
        margin: 0;
        line-height: 30px;
        color: var(--color-font-dark);

        @extend %text-heading-small;
        @include icon-size(30px);
      }
    }

    div[class~="react-datepicker__month"] {
      display: flex;
      flex-direction: column;
      margin: 0;

      gap: 5px;

      div[class~="react-datepicker__week"] {
        display: flex;
        flex-direction: row;

        gap: 10px;

        div[class~="react-datepicker__day"] {
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 30px;
          border-radius: 100px;
          margin: 0;
          color: var(--color-font-dark);

          &:hover {
            background-color: var(--color-background-secondary-dark);
          }

          @extend %text-body-medium;
          @include icon-size(30px);

          &[class~="react-datepicker__day--today"] {
            outline: 1px solid var(--color-border-dark);
          }

          &[class~="react-datepicker__day--keyboard-selected"] {
            color: inherit;
            background-color: inherit;
          }

          &[class~="react-datepicker__day--selected"] {
            color: var(--color-font-light);
            border-radius: 50%;
            background-color: var(--color-background-dark);

            &:hover {
              background-color: var(--color-background-dark);
            }
          }

          &[class~="react-datepicker__day--disabled"],
          &[class~="react-datepicker__day--outside-month"] {
            color: var(--color-font-secondary);
            outline-color: var(--color-border);
            border-radius: 50%;
            background-color: transparent;
          }
        }
      }
    }
  }
}
