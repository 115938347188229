@import "src/styles/textStyles";
@import "src/styles/iconSize";

.cellNumeric {
  justify-content: center;
  margin: 0 auto;
  text-align: center;

  & > button {
    width: fit-content;
  }
}

.cellClickable {
  font-size: inherit;
  overflow: hidden;
  width: 100%;
  text-align: start;
  text-overflow: ellipsis;
  color: var(--color-font-accent);

  &:hover {
    text-decoration: underline;
  }
}

.cellMenuDropdown {
  position: absolute !important;
  right: 15px;
  margin-left: auto;

  @include icon-size(20px !important);
}

.tableCardCell {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @extend %text-body-medium;
}

.tableCardCellLabel {
  font-weight: 500;
}

.tableCardCellValue {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
