@import "src/styles/textStyles";

.wrapper {
  position: absolute;
  bottom: 0;
  right: 20px;
  color: var(--color-font-secondary);

  @extend %text-body-extra-small;
}
