.tableCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0 0;
  border-bottom: 1px solid var(--color-border-light);

  &:last-child {
    border-bottom: none;
  }
}
