@import "src/styles/selectStyles";

.wrapper {
  flex: 1 1;
  max-width: 150px;
}

.dropdown {
  min-width: auto;
}
