@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-background-light);
  width: 100%;
  border-radius: 20px;
  padding: 24px 32px;

  gap: 24px;
}

.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px;
  background-color: var(--color-brand);

  & > svg {
    width: 25px;
    height: 25px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  @extend %text-heading-medium;
}

.name {
  color: var(--color-font-dark);
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.category {
  color: var(--color-font-disabled);
}

.description {
  color: var(--color-font-secondary);

  @extend %text-body-large;
}

.arrowIcon {
  margin-left: auto;
  color: var(--color-font-secondary);

  @include icon-size(35px);
}

@media screen and (max-width: $mobileScreen) {
  .wrapper {
    padding: 16px 24px;
  }
}
