@import "src/styles/select";

%option {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;

  gap: 5px;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.option > button {
  @extend %option;
}

.selectAllOption {
  @extend %option;
}
