.tableCardContent {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 30px 15px 45px;

  gap: 10px;
}

.tableCardBody {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.subTableCardBody {
  padding: 0 30px 15px 30px;
}
