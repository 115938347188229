@import "src/styles/sidebarList";
@import "src/styles/buttonWithIconAlignment";

.section {
  max-height: 100%;
  height: auto;
}

.sectionItemIcon > svg {
  color: var(--color-font-secondary);
}

.button {
  padding: 5px 10px;

  @extend %button-with-icon-alignment;
}

.sortSelect {
  flex: none !important;
  margin-left: auto;
  width: 160px;

  &:hover,
  &:focus-within,
  &:focus {
    input {
      outline: 1px solid var(--color-border);
    }
  }
}

.sortInput {
  div {
    background-color: transparent;
  }

  input {
    border: none;
  }
}

.lockIcon {
  color: var(--color-font-disabled);
}
