@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";

.table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;

  .tableWrapper {
    height: 100%;
    overflow: hidden;
  }

  div[class~="rs-table-row-header"] {
    border-bottom: 1px solid var(--color-border-light);

    @extend %text-label-thick-small;
  }

  div[class~="rs-table-cell-first"] > div {
    display: flex;
    align-items: center;
    padding-left: 14px;
    overflow: initial;
  }

  div[class~="rs-table-row"],
  div[class~="rs-table-cell"] {
    overflow: initial;

    @extend %text-label-thin-small;
  }

  div[class~="rs-table-row"]:hover {
    z-index: 1;
  }

  div[class~="rs-table-body-wheel-area"] {
    & > div {
      border-bottom: 1px solid var(--color-border-light);

      &:last-child {
        border-bottom: none;
      }
    }
  }

  div[role~="columnheader"][aria-colindex~="2"] > div {
    overflow: initial;
  }

  span[class~="rs-table-cell-expand-wrapper"] {
    margin-right: initial;
  }

  .checkBox {
    margin-left: initial;
  }

  button[class~="rs-pagination-btn-active"] {
    border: 1px solid var(--color-border) !important;

    span {
      display: none;
    }
  }

  div[class~="rs-table-scrollbar"] {
    div[class~="rs-table-scrollbar-handle"] {
      background-color: var(--color-grey-secondary-light);
    }
  }

  div[class~="rs-table-scrollbar-vertical"] {
    bottom: 0;
  }
}

.allKeyWordsCheckBox {
  display: flex;
  margin-right: 5px;
  margin-left: 16px;

  & > button {
    height: 20px;

    &:disabled {
      opacity: .5;
    }
  }
}

.totalVolumeWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  gap: 5px;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 55px;
  border-top: 1px solid var(--color-border-light);
}

.lineChart {
  width: 100%;
}

.tableLink > div {
  overflow: initial;
}

@media screen and (max-width: $tabletScreen) {
  .totalVolumeWrapper {
    overflow: hidden;

    & > span:first-child {
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
