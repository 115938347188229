@import "src/styles/textEllipsis";

.wrapper {
  max-width: 100%;
}

.tagWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  padding: 5px 10px;
  cursor: default;
  border: 2px solid;
  border-radius: 100px;

  &Visible {
    opacity: 1;
  }

  &Hidden {
    opacity: 0;
  }

  &.hoverValue:hover {
    .tagString {
      opacity: 0;

      &::after {
        opacity: 1;
      }
    }

    .tagValue {
      opacity: 1;
    }
  }

  &.transparent {
    border-color: transparent !important;
    background-color: transparent !important;
  }

  &.filled {
    padding: 5px 20px;
    color: var(--color-background-light) !important;
  }

  &.outlined {
    padding: 5px 20px;
    background-color: transparent !important;
  }
}

.tagContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.tagString {
  white-space: nowrap;
  text-transform: capitalize;
  text-align: center;
  opacity: 1;
  transition: opacity .1s ease-in;

  @extend %text-ellipsis;
}

.tagValue {
  width: auto;
  position: absolute;
  text-align: center;
  opacity: 0;
  transition: opacity .1s ease-in;
  left: 0;
  right: 0;

  @extend %text-ellipsis;
}

