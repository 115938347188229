@import "/src/styles/form";
@import "/src/styles/textStyles";
@import "/src/styles/screenSizes";

.formWrapper {
  gap: 25px;
}

.inputsWrapper {
  display: flex;
  flex-direction: column;

  gap: 20px;
}

.input {
  display: flex;
  width: 100%;
  color: var(--color-font-dark);
}

.passwordInput > div {
  width: 100%;
}

.resetPassword {
  display: flex;
  justify-content: center;
  gap: 8px;

  @extend %text-label-thin-small;
}

.link {
  font-weight: 500;
}

.button {
  width: 250px;
  margin: auto;
}
