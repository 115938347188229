@import "/src/styles/textStyles";
@import "/src/styles/spinAnimation";

.verificationInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 568px;
  padding: 24px;

  gap: 20px;
}

.title {
  @extend %text-heading-large;
}

.subTitle {
  text-align: center;

  @extend %text-body-large;
}

.note {
  color: var(--color-font-secondary);

  @extend %text-body-medium;
}

.link {
  font-weight: 500;

  @extend %text-body-medium;
}

.button {
  margin: 20px auto 0;
  width: 250px;
}

.loader {
  @include spin-animation(1s);
}
