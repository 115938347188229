@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/truncatedPlaceholder";

.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  width: 100%;
  height: 100%;

  gap: 5px;
}

.textarea {
  font-size: inherit;
  font-weight: inherit;
  width: 100%;
  height: 100%;
  max-height: inherit;
  color: inherit;
  border-radius: 4px;
  overflow: auto;
  padding: 0;
  text-overflow: ellipsis;
  resize: none;
  text-wrap: wrap;
  word-wrap: break-word;
  background-color: var(--color-background-light);
  border: 1px solid var(--color-border-light);

  &::placeholder {
    color: var(--color-font-disabled);
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &:read-only:not(:disabled) {
    background-color: var(--color-background-secondary);
    opacity: .5;
  }

  @extend %truncated-placeholder;
}

.limit {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-red-dark);

  @extend %text-body-medium;
}
