@import "src/styles/screenSizes";

.wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: rgba(0,0,0,.5);

  backdrop-filter: blur(5px);
  overflow-y: auto;
}

@media screen and (max-width: $mobileScreen) {
  .wrapper {
    padding: 8px;
  }
}
