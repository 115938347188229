@import "src/styles/textStyles";
@import "src/styles/iconSize";
@import "src/styles/textEllipsis";

.wrapper {
  width: 100%;
  min-width: 5px;
  height: 300px;
}

.label {
  position: absolute;
  left: 15px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  max-width: 100%;
  padding: 0 30px 0 35px;
  margin: auto;

  gap: 10px;

  @extend %text-body-small;
}

.date {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  color: var(--color-font-dark);

  gap: 5px;

  & > svg {
    @include icon-size(10px);
  }

  &:first-of-type {
    & > svg {
      transform: rotate(90deg);
    }
  }

  &:last-of-type {
    & > svg {
      transform: rotate(-90deg);
    }
  }
}

.name {
  white-space: nowrap;
  width: auto;

  @extend %text-ellipsis;
}
