@import "src/styles/screenSizes";
@import "src/styles/textStyles";
@import "src/styles/textEllipsis";

.tablePagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding: 20px 15px;
  width: 100%;

  gap: 10px;

  @extend %text-body-large;
}

.itemsPerPage {
  display: flex;
  align-items: center;
  flex: 1 1;
  color: var(--color-font-disabled);

  gap: 5px;

  @extend %text-body-medium;

  & > span {
    width: auto;
    white-space: nowrap;

    @extend %text-ellipsis;
  }
}

.selectPerPage {
  height: 25px;

  & > *:last-child {
    width: fit-content;
  }
}


.selectInputPerPage {
  height: 25px;

  & > div {
    position: static;
    align-items: center;
    border: none;
    height: 25px;

    gap: 5px;

    & > input {
      width: 60px;
      height: 25px;
      padding: 0;
      border-color: transparent;

      &:focus {
        border-color: transparent;
      }
    }
  }
}

.itemsCount {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  color: var(--color-font-disabled);

  @extend %text-body-medium;
}

.paginationContainer {
  display: flex;
  flex: 1 1;
  justify-content: center;
  margin: 0;
  padding: 0;

  gap: 5px;
}

@media screen and (max-width: $tabletScreen) {
  .tablePagination {
    padding: 15px;
  }
}

@media screen and (max-width: $tabletScreen) {
  .tablePagination {
    flex-wrap: wrap;

    gap: 15px 0;
  }

  .paginationContainer {
    flex: 0 1 100%;
    justify-content: center;
    order: -1;
  }
}
