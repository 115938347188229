@import "src/styles/sidebarList";
@import "src/styles/spinAnimation";

.buttons {
  display: flex;
  justify-content: center;
  margin: auto 0 0;

  gap: 25px;

  button {
    min-width: 150px;
  }
}

.sortSelect {
  flex: none !important;
  margin-left: auto;
  width: 160px;

  &:hover,
  &:focus-within,
  &:focus {
    input {
      outline: 1px solid var(--color-border);
    }
  }
}

.sortInput {
  div {
    background-color: transparent;
  }

  input {
    border: none;
  }
}

.sectionItemIcon > svg {
  color: var(--color-font-secondary);
}

.loader {
  @include spin-animation(1s);
}
