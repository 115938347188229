.app {
  position: relative;
  overflow: hidden;
  min-width: 360px;
  height: var(--app-height);
  margin: auto;
  color: var(--color-font-dark);
  background-color: var(--color-background-secondary-light);
}

.content {
  position: relative;
  height: 100%;
}

.section {
  overflow-y: auto;
  height: 100%;
}
