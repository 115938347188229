@import "src/styles/iconSize";

.sortIcon {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  border-radius: 2px;
  flex: 0 0 auto;

  gap: 1.5px;

  @include icon-size(10px);

  &:hover {
    background-color: var(--color-background-light);
  }

  &Asc svg:first-child {
    opacity: 1;
  }

  &Desc svg:last-child {
    opacity: 1;
  }

  svg {
    opacity: .2;

    @include icon-size(5px);

    &:last-child {
      transform: rotate(180deg);
    }
  }
}
