@import "src/styles/scrollbar";

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tagCloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  margin: auto;

  gap: 15px 25px;

  @extend %scrollbar;
}

.title {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
}
