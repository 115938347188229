@import "src/styles/form";
@import "src/styles/textStyles";
@import "src/styles/spinAnimation";

.title {
  text-align: center;

  @extend %text-heading-small;
}

.loader {
  @include spin-animation(1s);
}
