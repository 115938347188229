@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";
@import "src/styles/selectStyles";

.wrapper {
  width: 200px;
  position: relative;
}

.input > div[class*="InputWithIcon_wrapper"] > svg {
  color: var(--color-font-secondary);
  fill: var(--color-font-secondary);
}

.dropdown {
  svg {
    color: var(--color-font-secondary);
  }
}

.error {
  position: absolute;
  color: var(--color-red);
  bottom: -24px;
  text-wrap: nowrap;

  @extend %text-body-large;
}
