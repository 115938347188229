@import "src/styles/screenSizes";

.emptyTable {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 15px;
  text-align: center;

  svg {
    width: 200px;
    height: 100px;
  }
}

.emptyTableLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-font-disabled);

  & > a {
    color: var(--color-font-accent);
  }
}

@media screen and (max-width: $tabletScreen) {
  .emptyTableWrapper {
    flex: auto;
  }
}

@media screen and (max-width: $mobileScreen) {
  .emptyTableWrapper {
    svg {
      width: 100%;
      height: auto;
    }
  }
}
