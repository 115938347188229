@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 0;
  width: 100%;

  gap: 8px;

  & > div {
    flex: 1;
  }
}

.location,
.language {
  padding: 8px 16px;
  background-color: var(--color-background-secondary);
  border-radius: 20px;
  color: var(--color-font-secondary);
  line-height: 16px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @extend %text-body-medium;
}

.button {
  @extend %text-heading-extra-small;
}
