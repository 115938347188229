@import "src/styles/iconSize";
@import "src/styles/iconSize";
@import "src/styles/textStyles";
@import "src/styles/spinAnimation";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  border-radius: 12px;
  background-color: var(--color-background-light);
  box-shadow: var(--box-shadow-light);

  gap: 15px;
}

.header {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;

  gap: 20px
}

.content {
  flex: 1;
  min-height: 0;
}

.widgetInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  gap: 5px;
}

.widgetIcon {
  flex: 0 0 auto;

  @include icon-size(20px);
}

.widgetName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 26px;

  @extend %text-heading-medium;
}

.widgetIcons {
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;

  &:empty {
    display: none;
  }

  svg {
    @include icon-size(20px);
  }
}

.locked {
  pointer-events: none;
  filter: blur(2px);
}

.lock {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: .4;
  border-radius: 5px;

  & > svg {
    @include icon-size(100px);
  }
}

.loader {
  flex: 0 0 auto;
  color: var(--color-font-secondary);

  @include spin-animation(1s);
  @include icon-size(20px);
}

.preloader {
  height: 100%;
  border-radius: 4px;
  background-color: var(--color-background-light);
}

.snapshotPieChart {
  border-radius: 0;
  box-shadow: none;
  height: auto;
  min-height: 100%;

  div[class*="WidgetTile_content"] {
    min-height: 100%;
  }

  div[class*="WidgetTile_header"] {
    color: #000001;

    div[class*="WidgetTile_widgetIcons"] {
      display: none;
    }

    div[class*="WidgetTileButtons_wrapper"] {
      display: none;
    }

    div[class*="ExternalTooltip_wrapper"] {
      display: none;
    }
  }

  div[class*="WidgetSource_wrapper"] {
    display: none;
  }

  div[class*="PieChartWithLabels_labelsWrapper"] {
    max-height: initial;
  }
}

.snapshotPieChartExpanded {
  border-radius: 0;
  box-shadow: none;
  height: auto;
  min-height: 100%;

  div[class*="WidgetTile_content"] {
    display: flex;
    min-height: 100%;

    & > div {
      margin: auto 0;
    }
  }

  div[class*="WidgetTile_header"] {
    color: #000001;

    div[class*="WidgetTile_widgetIcons"] {
      display: none;
    }

    div[class*="WidgetTileButtons_wrapper"] {
      display: none;
    }

    div[class*="ExternalTooltip_wrapper"] {
      display: none;
    }
  }

  div[class*="WidgetSource_wrapper"] {
    display: none;
  }

  div[class*="PieChartWithLabels_labelsWrapper"] {
    max-height: initial;
  }
}

.snapshotLineChart {
  border-radius: 0;
  box-shadow: none;
  height: auto;
  min-height: fit-content;

  div[class*="WidgetTile_content"] {
    min-height: 100%;
  }

  div[class*="WidgetTile_header"] {
    color: #000001;

    div[class*="WidgetTile_widgetIcons"] {
      display: none;
    }

    div[class*="WidgetTileButtons_wrapper"] {
      display: none;
    }

    div[class*="ExternalTooltip_wrapper"] {
      display: none;
    }
  }

  div[class*="WidgetSource_wrapper"] {
    display: none;
  }

  div[class*="LineChart_lineChart"] {
    div[class*="LineChartSettings_wrapper"] {
      display: none;
    }

    div[class*="Labels_wrapper"] {
      width: 100%;

      div[class*="Labels_actions"] {
        display: none;
      }

      div[class*="Labels_labels"] {
        max-height: initial;
        overflow: initial;
        width: 100%;
      }
    }

    div[class*="LineChart_chartWrapperOuter"] {
      min-height: 300px;
    }

    div[class*="LineChart_chartWrapper"] {
      height: auto;
    }

    div[class*="recharts-responsive-container"] {
      display: flex;

      div[class*="recharts-wrapper"] {
        height: 100% !important;
        max-height: 100% !important;
      }

      svg[class*="recharts-surface"] {
        height: 100% !important;
        max-height: 100% !important;
      }
    }
  }
}

.snapshotLineChartExpanded {
  border-radius: 0;
  box-shadow: none;
  height: auto;
  min-height: fit-content;

  div[class*="WidgetTile_content"] {
    min-height: 100%;
  }

  div[class*="WidgetTile_header"] {
    color: #000001;

    div[class*="WidgetTile_widgetIcons"] {
      display: none;
    }

    div[class*="WidgetTileButtons_wrapper"] {
      display: none;
    }

    div[class*="ExternalTooltip_wrapper"] {
      display: none;
    }
  }

  div[class*="WidgetSource_wrapper"] {
    display: none;
  }

  div[class*="LineChart_lineChart"] {
    div[class*="LineChartSettings_wrapper"] {
      display: none;
    }

    div[class*="Labels_wrapper"] {
      width: 100%;

      div[class*="Labels_actions"] {
        display: none;
      }

      div[class*="Labels_labels"] {
        max-height: initial;
        overflow: initial;
        width: 100%;
      }
    }

    div[class*="LineChart_chartWrapperOuter"] {
      min-height: 600px;
    }

    div[class*="LineChart_chartWrapper"] {
      height: auto;
    }

    div[class*="recharts-responsive-container"] {
      display: flex;

      div[class*="recharts-wrapper"] {
        height: 100% !important;
        max-height: 100% !important;
      }

      svg[class*="recharts-surface"] {
        height: 100% !important;
        max-height: 100% !important;
      }
    }
  }
}

.snapshotTable,
.snapshotTableExpanded {
  border-radius: 0;
  box-shadow: none;
  min-height: fit-content;

  div[class*="WidgetTile_header"] {
    color: #000001;

    div[class*="WidgetTile_widgetIcons"] {
      display: none;
    }

    div[class*="WidgetTileButtons_wrapper"] {
      display: none;
    }

    div[class*="ExternalTooltip_wrapper"] {
      display: none;
    }
  }

  div[class*="WidgetSource_wrapper"] {
    display: none;
  }
}

.snapshotTagCloud,
.snapshotTagCloudExpanded {
  border-radius: 0;
  box-shadow: none;
  min-height: fit-content;

  div[class*="WidgetTile_header"] {
    color: #000001;

    div[class*="WidgetTile_widgetIcons"] {
      display: none;
    }

    div[class*="WidgetTileButtons_wrapper"] {
      display: none;
    }

    div[class*="ExternalTooltip_wrapper"] {
      display: none;
    }
  }

  div[class*="WidgetSource_wrapper"] {
    display: none;
  }
}

