.widgetPreview {
  display: flex;
  flex-direction: column;
  height: 100%;

  gap: 15px;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  gap: 10px;

  &:empty {
    display: none;
  }
}

.chartWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 0;

  gap: 15px;
}
