.table {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  border-collapse: collapse;
  word-break: break-all;
  border-radius: 8px 8px 0 0;
  background-color: var(--color-background-light);
}
