@import "src/styles/textStyles";
@import "src/styles/spinAnimation";

.wrapper {
  display: flex;
  flex-direction: column;

  gap: 30px;
}

.title {
  @extend %text-heading-small;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 15px;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  gap: 10px;
}

.sectionTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.button {
  min-width: 150px;
  margin: 0 0 0 auto;
}

.loader {
  @include spin-animation(1s);
}
