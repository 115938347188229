@import "src/styles/iconSize";
@import "src/styles/scrollbar";
@import "src/styles/textStyles";
@import "src/styles/screenSizes";
@import "src/styles/textEllipsis";

.wrapper {
  position: relative;
  display: flex;
  flex: 1 1;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.chartWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  gap: 30px;
}

.labelsWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  width: 100%;
  padding: 0 5px;
  max-height: 100%;
  min-width: 0;

  gap: 5px;
}

.labels {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  overflow: auto;

  gap: 5px;

  @extend %scrollbar;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.labelNameWrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1 1;

  gap: 5px;

  @extend %text-body-medium;
}

.labelTotalWrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 0;
  flex: 1 1;
  font-weight: 500;

  gap: 5px;

  @extend %text-body-medium;
}

.labelIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  border-radius: 50%;

  @include icon-size(10px);
}

.labelName {
  white-space: nowrap;

  @extend %text-ellipsis;
}

.labelValue {
  flex: 1 1;
  font-weight: 500;
  min-width: 0;

  @extend %text-body-medium;
}

.chart {
  flex: 1 1 0;
  width: 100%;
  height: 90%;
  cursor: default;
}

.tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 4px;
  z-index: 10;
  max-width: 300px;
  color: var(--color-font-light);
  background: rgba(36, 43, 67, .9);
  pointer-events: none;

  gap: 10px;
}

.tooltipTitle {
  line-height: 14px;

  @extend %text-body-medium;
}

.tooltipItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  @extend %text-body-small;

  gap: 10px;
}

.tooltipItemTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;

  gap: 5px;
}

.tooltipItemIcon {
  flex: 0 0 auto;
  border-radius: 50%;

  @include icon-size(10px);
}

@media screen and (max-width: $mobileScreen) {
  .chartWrapper {
    flex-direction: column;
  }

  .labelsWrapper {
    flex: initial;
    max-height: 35%;
  }
}
