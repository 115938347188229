@import "src/styles/textStyles";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  min-width: 0;

  gap: 10px;

  @extend %text-body-small;
}

.button {
  padding: 0;
  height: 22px;

  &Inactive {
    opacity: 0.3;
  }
}
